import { ICategory } from "./category";
import { IMetric } from "./metric";
export interface ICategoryState {
  listCategory: ICategory[];
  metrics: IMetric[];
  loading: boolean;
  showTable: boolean;
  dataTable: any;
}
export class CategoryState implements ICategoryState {
  listCategory: ICategory[];
  metrics: IMetric[];
  loading: boolean;
  showTable: boolean;
  dataTable: any;

  constructor(object?: ICategoryState) {
    this.listCategory = object?.listCategory ?? [];
    this.loading = object?.loading ?? false;
    this.showTable = object?.showTable ?? false;
    this.metrics = object?.metrics ?? [];
    this.dataTable = object?.dataTable ?? null
  }
}
