import { useState, useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import './styles.scss';
import { Dropdown, DropdownButton, FormControl } from 'react-bootstrap';
import {connect} from 'react-redux';
import { updateSelectedWebs, updateSelectedFLApps, updateSelectedRNApps } from '../filter.actions';

const DropdownFilter = ({
    listCat,
    title,
    type,
    updateSelectedWebs,
    updateSelectedFLApps,
    updateSelectedRNApps,
    filter,
}) => {
    const [searchKeyword, setSearchKeyword] = useState('');
    const [selectedCats, setSelectedCats] = useState([]);
    const [selectAllOptionEnabled, setSelectAllOptionEnabled] = useState(false);

    useEffect(() => {
        if (type === 'web') {
            setSelectedCats(filter.webs);
        } else if (type === 'flapp') {
            setSelectedCats(filter.flapps);
        } else if (type === 'rnapp') {
            setSelectedCats(filter.rnapps);
        }
    }, [filter]);

    const toggleCheckbox = cat => {
        let selectedCatsNewValue;
        if (cat) {
            if (catIsSelected(cat)) {
                selectedCatsNewValue = selectedCats.filter(_c => _c.name !== cat.name);
            } else {
                selectedCatsNewValue = selectedCats.concat(cat);
            }
        } else if (selectAllOptionEnabled) {
            selectedCatsNewValue = [];
            setSelectAllOptionEnabled(false);
        } else {
            selectedCatsNewValue = listCat;
            setSelectAllOptionEnabled(true);
        }
        setSelectedCats(selectedCatsNewValue);
        updateFilter(selectedCatsNewValue);
    }

    const updateFilter = value => {
        if (type === 'web') {
            updateSelectedWebs(value);
        } else if (type === 'flapp') {
            updateSelectedFLApps(value);
        } else if (type === 'rnapp') {
            updateSelectedRNApps(value);
        }
    }

    const getDropdownTitle = () => {
        let result = title;
        if (selectedCats.length > 3) {
            result += ': ' + selectedCats.length + ' selected';
        } else if (selectedCats.length > 0) {
            selectedCats.forEach(cat => {
                result += ' ' + cat.name;
            });
        }
        return result;
    }

    const catIsSelected = cat => {
        return selectedCats.find(_c => _c.name === cat.name) !== undefined;
    }

    return (
        <div className='dropdown-filter'>
            <DropdownButton className='dropdown' title={getDropdownTitle()}>
                <FormControl
                    autoFocus
                    className="mx-2 my-2 w-auto"
                    placeholder="Type to filter..."
                    onChange={(e) => setSearchKeyword(e.target.value)}
                    value={searchKeyword}
                />
                <Dropdown.ItemText>
                    <Checkbox
                        checked={selectAllOptionEnabled}
                        onChange={() => toggleCheckbox()}
                    />
                    <div onClick={() => toggleCheckbox()}>Select all</div>
                </Dropdown.ItemText>
                <Dropdown.Divider />
                {listCat.filter(cat => cat.name.toLowerCase().indexOf(searchKeyword) > -1).map((cat, index) => {
                    return (
                        <Dropdown.ItemText key={index}>
                            <Checkbox
                                checked={catIsSelected(cat)}
                                onChange={() => toggleCheckbox(cat)}
                            />
                            <div onClick={() => toggleCheckbox(cat)}>{cat.name}</div>
                        </Dropdown.ItemText>
                    );
                })}
            </DropdownButton>
        </div>
    );
};

function mapStateToProps(state, props) {
    return {
        filter: state.filterReducer,
    };
}

export default connect(mapStateToProps, {updateSelectedWebs, updateSelectedFLApps, updateSelectedRNApps})(DropdownFilter);
