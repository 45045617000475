import { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import Constants from '../../../utils/constants';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Button from 'react-bootstrap/Button';
import './styles.scss';
import {setupFilter} from '../filter.actions';

const SavedFilters = ({filter, setupFilter}) => {
    const [savedFilters, setSavedFilters] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState(-1);

    useEffect(() => {
        const fetchSavedFilters = async() => {
            const res = await axios.get(Constants.API_ROOT_URL + '/saved-filters/?email=' + email);
            setSavedFilters(res.data);
        }

        const email = localStorage.getItem('email');
        if (email) {
            fetchSavedFilters();
        }
    }, []);

    const selectFilter = index => {
        if (index >= 0) {
            if (index !== selectedFilter) {
                if (window.confirm('Are you sure to use this filter?')) {
                    setSelectedFilter(index);
                    setupFilter(JSON.parse(savedFilters[index].content));
                    setTimeout(() => document.getElementById('btn-filter').click(), 1000);
                }
            }
        } else {
            setSelectedFilter(index);
        }
    }

    const save = async() => {
        const email = localStorage.getItem('email');
        if (email) {
            if (selectedFilter === -1) {
                const filterName = window.prompt('Enter new filter\'s name');
                if (filterName !== null) {
                    const res = await axios.post(Constants.API_ROOT_URL + '/saved-filters/', {
                        name: filterName,
                        content: JSON.stringify(filter),
                        email
                    });
                    setSelectedFilter(savedFilters.length);
                    setSavedFilters(savedFilters.concat(res.data));
                    alert('New filter created');
                }
            } else {
                await axios.patch(Constants.API_ROOT_URL + '/saved-filters/' + savedFilters[selectedFilter].id + '/', {
                    content: JSON.stringify(filter),
                });
                alert('Filter updated');
            }
        }
    }

    const deleteFilter = async() => {
        if (window.confirm('Are you sure to delete this filter')) {
            await axios.delete(Constants.API_ROOT_URL + '/saved-filters/' + savedFilters[selectedFilter].id + '/');
            setSavedFilters([...savedFilters.slice(0, selectedFilter), ...savedFilters.slice(selectedFilter + 1, savedFilters.length)]);
            setSelectedFilter(-1);
            alert('Filter deleted');
        }
    }

    return (
        <div className='saved-filters-container'>
            <FormControl className='saved-filters-select' variant='outlined'>
                <InputLabel>
                    Saved Filters
                </InputLabel>
                <Select value={selectedFilter} label='Saved Filters' onChange={event => selectFilter(event.target.value)}>
                    <MenuItem value={-1}>
                        Select filter
                    </MenuItem>
                    {savedFilters.map((filter, index) => (
                        <MenuItem value={index} key={index}>
                            {filter.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {selectedFilter === -1 ? (
                <Button variant='success' onClick={save}>Create new filter</Button>
            ) : (
                <Button variant='outline-success' onClick={save}>Update filter</Button>
            )}
            {selectedFilter > -1 && (
                <Button variant='danger' onClick={deleteFilter}>Delete filter</Button>
            )}
        </div>
    );
}

function mapStateToProps(state, props) {
    return {
        filter: state.filterReducer,
    };
}

export default connect(mapStateToProps, {setupFilter})(SavedFilters);