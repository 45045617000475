import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { Box, Card, Container, Link } from '@material-ui/core';
import axios from 'axios';
import Constants from '../../utils/constants';
const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        // fontSize: 14,
        fontWeight: 'bold',
    },
    pos: {
        marginBottom: 12,
    },
    appName: {
        fontWeight: 'bold',
        marginTop: '30px',
    },
    result: {},
    page: {
        display: 'flex',
        width: '100%',
    },
    labelPage: {
        flex: '1',
    },
    pageActive: {
        flex: '1',
        color: 'green',
        textAlign: 'end',
    },
    pageError: {
        flex: '1',
        color: 'red',
        textAlign: 'end',
    },
    listLinkError: {
        width: '100%',
    },
    linkErrorTitle: {
        display: 'flex',
        '& p': {
            flex: '1',
        },
        '& p:nth-child(2)': {
            textAlign: 'end',
        },
    },
    linkErrorContent: {
        display: 'flex',
        color: 'red',
        '& p': {
            flex: '1',
            marginBottom: '10px',
        },
        '& p:nth-child(2)': {
            textAlign: 'end',
        },
    },
    linkError: {
        color: 'red',
        '& a': {
            color: 'red',
        },
    },
});
export default function WebStatusBody() {
    const classes = useStyles();
    const [dataWebStatus, setDataWebStatus] = useState([]);
    const [expanded, setExpanded] = React.useState('');
    useEffect(async () => {
        getDataWebStatus(Constants.URL_WEB_STATUS);
    }, []);

    const getDataWebStatus = async (url) => {
        const response = await axios.post(url);
        const dataResponse =
            response.status == 200 &&
            response?.data.sort((a, b) => {
                const categoryA = a.category;
                const categoryB = b.category;
                if (categoryA < categoryB) {
                    return -1;
                }
                if (categoryA > categoryB) {
                    return 1;
                }

                return 0;
            });
        setDataWebStatus(dataResponse);
    };
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const ListPage = ({ list }) => {
        return (
            <Box className={classes.listLinkError}>
                <Box className={classes.linkErrorTitle}>
                    <Typography>Link</Typography>
                    <Typography>Status code</Typography>
                </Box>
                {list &&
                    list.map((item) => {
                        const status = item.status;
                        const link = item.link;
                        return (
                            <Box className={classes.linkErrorContent}>
                                <Typography className={classes.linkError}>
                                    <Link href={link} variant='body2'>
                                        {link}
                                    </Link>
                                </Typography>
                                <Typography className='status-code'>{status}</Typography>
                            </Box>
                        );
                    })}
            </Box>
        );
    };
    return (
        <Container>
            <Typography className={classes.title} gutterBottom>
                App Name
            </Typography>
            {dataWebStatus &&
                dataWebStatus.map((item, index) => {
                    const appName = item.category;
                    const page = item.page;
                    return (
                        <Box className={classes.content}>
                            <Typography className={classes.appName} gutterBottom>
                                {appName}
                            </Typography>
                            {/* <p className='app-name'>{appName}</p> */}
                            {page &&
                                page?.map((item, index) => {
                                    const pageId = item?.id;
                                    const labelPage = item?.label;
                                    const list = item?.list;
                                    const length = list?.length;
                                    const error = list?.filter(
                                        (i) => !i.status.toString().indexOf('2') == 0
                                    );
                                    const errorNum = error.length;
                                    const panel = appName + 'panel' + index + 1;

                                    return (
                                        <Card className='result'>
                                            <Accordion
                                                square
                                                expanded={expanded === panel}
                                                onChange={handleChange(panel)}
                                                className='list-page'
                                            >
                                                <AccordionSummary
                                                    aria-controls={panel + 'd-content'}
                                                    id={panel + 'd-header'}
                                                >
                                                    <Box className={classes.page}>
                                                        <Typography className={classes.labelPage}>
                                                            {labelPage.charAt(0).toUpperCase() +
                                                                labelPage.slice(1)}{' '}
                                                        </Typography>
                                                        {errorNum == 0 ? (
                                                            <Typography
                                                                className={classes.pageActive}
                                                            >
                                                                {length} / {length}
                                                            </Typography>
                                                        ) : (
                                                            <Typography
                                                                className={classes.pageError}
                                                            >
                                                                {errorNum} / {length}
                                                            </Typography>
                                                        )}
                                                    </Box>
                                                </AccordionSummary>
                                                {errorNum > 0 && (
                                                    <AccordionDetails>
                                                        <ListPage list={error} />
                                                    </AccordionDetails>
                                                )}
                                            </Accordion>
                                        </Card>
                                    );
                                })}
                        </Box>
                    );
                })}
        </Container>
    );
}
