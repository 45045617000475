import React from 'react';
import ContentChecker from './ContentChecker';
import { Container, Row } from 'reactstrap';
export const ContentCheckerScreen = () => {
  return (
    <div>
      <Container>
        <Row>
          <ContentChecker />
        </Row>
      </Container>
    </div>
  );
};
