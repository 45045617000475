import React from 'react';
import ReactPaginate from 'react-paginate';

const Pagination = (props) => {
  const { PER_PAGE, questions, handlePage } = props;
  return (
    <ReactPaginate
      previousLabel={'← Previous'}
      nextLabel={'Next →'}
      pageCount={Math.ceil(questions.length / PER_PAGE)}
      onPageChange={handlePage}
      disabledClassName={'pagination__link--disabled'}
      breakClassName={'page-item'}
      breakLinkClassName={'page-link'}
      containerClassName={'pagination'}
      pageClassName={'page-item'}
      pageLinkClassName={'page-link'}
      previousClassName={'page-item'}
      previousLinkClassName={'page-link'}
      nextClassName={'page-item'}
      nextLinkClassName={'page-link'}
      activeClassName={'active'}
    />
  );
};

export default Pagination;
