import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useState, useEffect } from 'react';
import { formatDate } from '../../../utils/utils';
import Pickers from '../DateTimePicker';
import './styles.scss';
import {connect} from 'react-redux';
import { updateStartDate, updateEndDate } from '../filter.actions';
import Button from 'react-bootstrap/Button';
import FormHelperText from '@material-ui/core/FormHelperText';

const TIME_FILTER_LIST = [
    {
        label: 'Yesterday',
        offset: 1,
    },
    {
        label: 'Last 7 days',
        offset: 7,
    },
    {
        label: 'Last 28 days',
        offset: 28,
    },
    {
        label: 'Last 90 days',
        offset: 90,
    },
    {
        label: 'Last 180 days',
        offset: 180,
    },
    {
        label: 'Custom',
        offset: -1,
    },
];

const ONE_DAY_MILLISECONDS = 1000 * 60 * 60 * 24;

const TimeFilter = ({startDate, endDate, updateStartDate, updateEndDate}) => {
    const [selectedOffset, setSelectedOffset] = useState(7);
    const [showCustomTime, setShowCustomTime] = useState(false);

    useEffect(() => {
        updateStartDate(formatDate(new Date(new Date().getTime() - selectedOffset * ONE_DAY_MILLISECONDS)));
        updateEndDate(formatDate(new Date(new Date().getTime() - ONE_DAY_MILLISECONDS)));
    }, []);

    const convertOffsetToTimeRange = offset => {
        let startDate = formatDate(
            new Date(new Date().getTime() - offset * ONE_DAY_MILLISECONDS)
        );
        let endDate = formatDate(
            new Date(new Date().getTime() - ONE_DAY_MILLISECONDS)
        );
        updateStartDate(startDate);
        updateEndDate(endDate);
    };

    const onChangeTime = offset => {
        console.log('toto', offset);
        if (offset != -1) {
            convertOffsetToTimeRange(offset);
            setShowCustomTime(false);
        } else {
            setShowCustomTime(true);
        }

        setSelectedOffset(offset);
    }

    return (
        <div className='time-filter-container'>
            <FormControl className='time-filter-control' variant='outlined'>
                <InputLabel>Chọn ngày</InputLabel>
                <Select value={selectedOffset} label='Chọn ngày' onChange={event => onChangeTime(event.target.value)}
                    onClick={() => {
                        setTimeout(() => {
                            if (selectedOffset === -1) {
                                setShowCustomTime(true);
                            }
                        }, 500);
                    }}>
                    {TIME_FILTER_LIST.map(time => (
                        <MenuItem key={time.label} value={time.offset}>
                            {time.label}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText>{formatDate(new Date(startDate), 'dd/mm/yyyy')} - {formatDate(new Date(endDate), 'dd/mm/yyyy')}</FormHelperText>
            </FormControl>
            {showCustomTime && (
                <div className='custom-time-container'>
                    <Pickers />
                    <Button onClick={() => setShowCustomTime(false)}>Done</Button>
                </div>
            )}
        </div>
    );
}

function mapStateToProps(state, props) {
    return {
        startDate: state.filterReducer.startDate,
        endDate: state.filterReducer.endDate,
    }
}

export default connect(mapStateToProps, {updateStartDate, updateEndDate})(TimeFilter);
