import { IMetric } from "../../models/metric";
import { ICategory } from "../../models/category";
import Constants from "../../utils/constants";
import { SearchChartAction, SearchChartActionType } from "../actions/searchChart";

export interface SearchChartState {
    metric: IMetric[];
    dataChart: null,
    platformChart: number;
    loading: boolean;
    nameChart: string[]
}
const initState: SearchChartState = {
    metric: [],
    dataChart: null,
    platformChart: 0,
    nameChart: [],
    loading: false
};
function searchChartState(
    state: SearchChartState = initState,
    action: SearchChartAction
): SearchChartState {
    switch (action.type) {
        case SearchChartActionType.SEARCH_CHART:
            return {
                ...state,
                nameChart: [...action.nameChart ?? []],
                loading: true
            }
        case SearchChartActionType.SEARCH_CHART_SUCCESS:
            return {
                ...state,
                dataChart: { ...action.dataChart },
                metric: [...action.listMetric ?? []],
                loading: false
            }
        case SearchChartActionType.REMOVE_ALL:
            return {
                ...state,
                metric: [],
                dataChart: null,
                platformChart: 0,
                nameChart: [],
                loading: false
            }
        case SearchChartActionType.SEARCH_CHART_FAILED:
            return {
                ...state,
                metric: [],
                dataChart: null,
                platformChart: 0,
                nameChart: [],
                loading: false
            }
        default:
            return state;
    }
}

export default searchChartState;
