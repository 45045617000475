import { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import Constants from '../../../utils/constants';
import { formatResultByMetric, formatDateToDayAndMonth, formatNumber, lightenDarkenColor } from '../../../utils/utils';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux';
import { LineChart, Line, Tooltip, XAxis, YAxis, BarChart, Bar, Cell, ResponsiveContainer } from 'recharts';
import './styles.scss';

const TrafficChart = ({ type, result, metricList, catList, startDate, endDate }) => {
    const [data, setData] = useState({});
    const [selectedCats, setSelectedCats] = useState(undefined);
    const [currentChartType, setCurrentChartType] = useState('bar');
    const [selectAllOptionEnabled, setSelectAllOptionEnabled] = useState(true);

    useEffect(() => {
        const data = {};
        const metricList = type === 'web' ? Constants.LIST_WEB_METRICS : Constants.LIST_APP_METRICS;
        for (let i = 0; i < metricList.length; i++) {
            const metric = metricList[i].value;
            data[metric] = [];
            const dateRange = getDaysArray(startDate, endDate);
            for (let j = 0; j < dateRange.length; j++) {
                const dataByDay = {
                    day: formatDateToDayAndMonth(dateRange[j]),
                    weekDay: formatDateToDayAndMonth(dateRange[j], true),
                }
                for (let k = 0; k < result.length; k++) {
                    const foundDay = result[k].dayByDayResult.find(day => day.day === dateRange[j]);
                    dataByDay[result[k].name] = foundDay ? formatNumber(foundDay[metric], metric, 'value') : 0;
                }
                data[metric].push(dataByDay);
            }
        }
        setData(data);
        if (selectedCats === undefined) {
            setSelectedCats(catList);
        }
    }, [result]);

    const getDaysArray = (start, end) => {
        start = new Date(start);
        end = new Date(end);
        for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
            const currentDate = new Date(dt);
            arr.push(currentDate.getFullYear() + ('0' + (currentDate.getMonth() + 1)).slice(-2) + ('0' + currentDate.getDate()).slice(-2));
        }
        return arr;
    }

    const toggleSelectedCats = (cat = null) => {
        if (cat !== null) {
            if (selectedCats.indexOf(cat.name) > -1) {
                setSelectedCats(selectedCats.filter(name => name !== cat.name));
            } else {
                setSelectedCats(selectedCats.concat(cat.name));
            }
        } else if (selectAllOptionEnabled) {
            setSelectedCats([]);
            setSelectAllOptionEnabled(false);
        } else {
            setSelectedCats(catList);
            setSelectAllOptionEnabled(true);
        }
    }

    const flattenAggregateResult = (metric, cat) => {
        if (type === 'web') {
            return {
                ...cat,
                [metric.value]: formatNumber(cat.aggregateResult[metric.value], metric.value, 'value')
            };
        }
        return {
            ...cat,
            [metric.value + '_ios']: formatNumber(cat.aggregateResult[metric.value + '_ios'], metric.value, 'value'),
            [metric.value + '_android']: formatNumber(cat.aggregateResult[metric.value + '_android'], metric.value, 'value'),
        }
    }

    const CustomLineTooltip = ({ active, payload, metric }) => {
        if (active && payload && payload.length) {
            payload.sort((a, b) => parseFloat(b.value) - parseFloat(a.value));
            return (
                <div className='custom-tooltip'>
                    <div className='custom-tooltip-label'>{payload[0]['payload']['weekDay']}</div>
                    {payload.map((cat, index) => (
                        <div className='custom-tooltip-value' key={index} style={{color: cat['color']}}>
                            <span>{cat['name']}:</span>
                            <span>{formatResultByMetric(metric.value, cat['value'])}</span>
                        </div>
                    ))}
                </div>
            );
        }

        return null;
    }

    const CustomBarTooltip = ({ active, payload, metric }) => {
        if (active && payload && payload.length) {
            let label;
            if (type === 'web') {
                label = Constants.LIST_WEB_METRICS.find(metric => metric.value === payload[0]['name']);
            } else {
                label = Constants.LIST_APP_METRICS.find(metric => payload[0]['name'].indexOf(metric.value) > -1);
            }
            if (label) {
                label = label.label;
            }
            return (
                <div className='custom-tooltip'>
                    <div className='custom-tooltip-label'>{payload[0]['payload']['name']} {label}</div>
                    {payload.map((cat, index) => (
                        <div className='custom-tooltip-value' key={index} style={{color: cat['color']}}>
                            <span>{cat['name']}:</span>
                            <span>{formatResultByMetric(metric.value, cat['value'])}</span>
                        </div>
                    ))}
                    {payload.length > 1 && (
                        <div className='custom-tooltip-total'>
                            Total: {formatResultByMetric(metric.value, payload.reduce((total, cat) => {
                                return total + parseFloat(cat['value']);
                            }, 0))}
                        </div>
                    )}
                </div>
            );
        }

        return null;
    }

    const getLoadingStatus = () => {
        let nbLoadedCats;
        if (type === 'web') {
            nbLoadedCats = result.filter(cat => cat.aggregateResult !== {} && cat.dayByDayResult.length > 0).length;
        } else {
            nbLoadedCats = result.filter(cat => cat.aggregateResult !== {}).length;
        }
        if (nbLoadedCats < result.length) {
            return nbLoadedCats + '/' + result.length;
        }
        return '';
    }

    const getTotalActiveUsers = metric => {
        let total = 0;
        for (let i = 0; i < result.length; i++) {
            if (!isNaN(result[i].aggregateResult[metric])) {
                total += parseInt(result[i].aggregateResult[metric]);
            }
        }
        return total;
    }

    return (
        <div className='chart-panel'>
            <div className='panel-type'>{type} traffic <span>{getLoadingStatus()}</span></div>
            <Tabs defaultActiveKey={metricList[0].value} variant='tabs'>
                {metricList.map((metric, index) => (
                    <Tab eventKey={metric.value} title={metric.label + (metric.value === 'ga:users' ? ' | Total: ' + getTotalActiveUsers(metric.value) : '')} key={index}>
                        {selectedCats && (
                            <>
                                <div className='chart-types'>
                                    {result.find(cat => cat.dayByDayResult.length > 0) !== undefined && (
                                        <img src={'/line-chart' + (currentChartType === 'line' ? '-active' : '') + '.png'}
                                            onClick={() => setCurrentChartType('line')} />
                                    )}
                                    {result.find(cat => {
                                        if (type === 'web') {
                                            return cat.aggregateResult[metric.value] !== undefined;
                                        } else {
                                            return cat.aggregateResult[metric.value + '_ios'] !== undefined || cat.aggregateResult[metric.value + '_android'] !== undefined;
                                        }
                                    }) && (
                                        <img src={'/bar-graph' + (currentChartType === 'bar' ? '-active' : '') + '.png'}
                                            onClick={() => setCurrentChartType('bar')} />
                                    )}
                                </div>
                                <div className='chart-container'>
                                    {currentChartType === 'line' ? (
                                        <ResponsiveContainer height={400} width='100%'>
                                            <LineChart data={data[metric.value]}>
                                                {result.filter(cat => selectedCats.indexOf(cat.name) > -1)
                                                    .map((cat, catIndex) => (
                                                        <Line type="monotone" dataKey={cat.name} key={catIndex} stroke={cat.color} dot={true} />
                                                    ))}
                                                <XAxis dataKey='day' padding={{ left: 10 }} />
                                                <YAxis />
                                                <Tooltip content={<CustomLineTooltip metric={metric} />} />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    ) : (
                                        <div>
                                            <ResponsiveContainer height={400} width='100%'>
                                                <BarChart data={result
                                                    .filter(cat => selectedCats.indexOf(cat.name) > -1)
                                                    .map(cat => flattenAggregateResult(metric, cat))}>
                                                    {type === 'web' && (
                                                        <Bar type="monotone" dataKey={metric.value}>
                                                            {
                                                                result.map((entry, index) => {
                                                                    return <Cell fill={result[index].color} key={index} />;
                                                                })
                                                            }
                                                        </Bar>
                                                    )}
                                                    {type === 'flapp' && (
                                                        <Bar type="monotone" dataKey={metric.value + '_ios'} stackId={metric.value}>
                                                            {
                                                                result.map((entry, index) => {
                                                                    return <Cell fill={result[index].color} key={index} />;
                                                                })
                                                            }
                                                        </Bar>
                                                    )}
                                                    {type === 'flapp' && (
                                                        <Bar type="monotone" dataKey={metric.value + '_android'} stackId={metric.value}>
                                                            {
                                                                result.map((entry, index) => {
                                                                    return <Cell fill={lightenDarkenColor(result[index].color, 30)} key={index} />;
                                                                })
                                                            }
                                                        </Bar>
                                                    )}
                                                    <XAxis dataKey='name' padding={{ left: 10 }} />
                                                    <YAxis />
                                                    <Tooltip content={<CustomBarTooltip metric={metric} />} cursor={{fill: 'rgba(0, 0, 0, 0)'}} />
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </div>
                                    )}

                                    <div className='filter-by-category'>
                                        <div>
                                            <Checkbox
                                                checked={selectAllOptionEnabled}
                                                onChange={() => toggleSelectedCats()}
                                            />
                                            <span onClick={() => toggleSelectedCats()}>
                                                Select all
                                            </span>
                                        </div>
                                        {result.map((cat, index) => (
                                            <div key={index}>
                                                <Checkbox
                                                    checked={selectedCats.indexOf(cat.name) > -1}
                                                    onChange={() => toggleSelectedCats(cat)}
                                                    style={{ color: cat.color }}
                                                />
                                                <span onClick={() => toggleSelectedCats(cat)} style={{ color: cat.color }}>
                                                    {cat.name}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </>
                        )}
                    </Tab>
                ))}
            </Tabs>
        </div>
    );
}

function mapStateToProps(state, props) {
    return {
        startDate: state.filterReducer.startDate,
        endDate: state.filterReducer.endDate,
    }
}

export default connect(mapStateToProps, {})(TrafficChart);