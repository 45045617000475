import Constants from '../../utils/constants';
import DropdownFilter from './DropdownFilter';
import TimeFilter from './TimeFilter';
import './styles.scss';
import Button from 'react-bootstrap/Button';
import {connect} from 'react-redux';
import axios from 'axios';
import {convertDateToTimestamp} from '../../utils/utils';
import {
    updateWebsResult,
    updateWebsDayByDayResult,
    updateWebsAggregateItemInResult,
    updateWebsRevenue,
    updateAppsResult,
    updateAppsAggregateItemInResult,
    updateFLAppDayByDayResult,
    updateAppsRevenue,
    updateLearnAnswers,
    updateTestAnswers,
} from '../Result/result.actions';
import SavedFilters from './SavedFilters';

const Filter = ({
    filter,
    userPermissions,
    updateWebsResult,
    updateWebsDayByDayResult,
    updateWebsAggregateItemInResult,
    updateWebsRevenue,
    updateAppsResult,
    updateAppsAggregateItemInResult,
    updateFLAppDayByDayResult,
    updateAppsRevenue,
    updateLearnAnswers,
    updateTestAnswers,
}) => {
    const fetchData = () => {
        fetchWebsData();
        fetchAppsData('flapp');
        fetchAppsData('rnapp');
    }

    const fetchWebsData = () => {
        updateWebsResult(filter.webs.map(cat => ({
            ...cat,
            aggregateResult: {},
            dayByDayResult: [],
        })));
        fetchWebsTraffic();
    }

    const fetchWebsTraffic = () => {
        for (let i = 0; i < filter.webs.length; i++) {
            const params = [];
            params.push('viewID=' + filter.webs[i].webId);
            params.push('startDate=' + filter.startDate);
            params.push('endDate=' + filter.endDate);
            let metrics = Constants.LIST_WEB_METRICS.filter(metric => metric.route === undefined)
            .map(metric => metric.value).join(',');
            if (filter.webs[i].hasAds) {
                metrics += ',ga:adsenseRevenue';
            }
            params.push('metrics=' + metrics);
            axios.get(Constants.API_ROOT_URL + '/web-traffic/get-metrics-without-dimensions?' + params.join('&'))
                .then(response => {
                    Constants.LIST_WEB_METRICS.filter(metric => metric.route === undefined).forEach(metric => {
                        updateWebsAggregateItemInResult(filter.webs[i].name, metric.value, response.data[metric.value]);
                    });
                    if (response.data['ga:adsenseRevenue']) {
                        updateWebsRevenue(filter.webs[i].name, 'ads_revenue', parseInt(response.data['ga:adsenseRevenue']));
                    }
                });
            axios.get(Constants.API_ROOT_URL + '/web/get-pro-revenue?slug=' + filter.webs[i].slug + '&startDate=' + filter.startDate + '&endDate=' + filter.endDate)
                .then(response => {
                    const nbBuyersByTest = response.data.nbBuyers;
                    updateWebsRevenue(filter.webs[i].name, 'pro_revenue', parseInt(response.data.revenue));
                    updateWebsRevenue(filter.webs[i].name, 'pro_nb_buyers_by_tests', nbBuyersByTest);
                    updateWebsRevenue(filter.webs[i].name, 'pro_total_buyers', Object.values(nbBuyersByTest).reduce((total, nb) => total + nb, 0));
                });
            params.push('dimensions=ga:date');
            axios.get(Constants.API_ROOT_URL + '/web-traffic/get-metrics-with-dimensions?' + params.join('&'))
                .then(response => updateWebsDayByDayResult(filter.webs[i].name, response.data));
            Constants.LIST_WEB_METRICS.filter(metric => metric.route).forEach(metric => {
                axios.get(Constants.API_ROOT_URL + metric.route + '?' + params.join('&'))
                    .then(response => updateWebsAggregateItemInResult(filter.webs[i].name, metric.value, response.data));
            });
        }
    }

    const fetchAppsData = appType => {
        updateAppsResult(appType, filter.flapps.map(cat => ({
            ...cat,
            aggregateResult: {},
            dayByDayResult: [],
        })));
        fetchAppsTraffic(appType);
        fetchAppsRevenue(appType);
        if (appType === 'flapp') {
            filter.flapps.forEach(cat => {
                fetchAppsLearnAnswersStatistic(cat);
                fetchAppsTestAnswersStatistic(cat);
            });
        }
    }

    const fetchAppsTraffic = appType => {
        axios.get(Constants.API_ROOT_URL + '/app-traffic/get-metrics-without-dimensions?appType=' + appType + '&startDate=' + filter.startDate + '&endDate=' + filter.endDate)
            .then(response => {
                const filteredApps = filter[appType + 's'];
                for (let i = 0; i < filteredApps.length; i++) {
                    const iosData = response.data.find(app => app.appID === (appType === 'flapp' ? filteredApps[i].flAppIosId : filteredApps[i].rnAppIosId));
                    if (iosData) {
                        Constants.LIST_APP_METRICS.forEach(metric => {
                            updateAppsAggregateItemInResult(appType, filteredApps[i].name, metric.value + '_ios', iosData[metric.value]);
                        });
                    }
                    const androidData = response.data.find(app => app.appID === (appType === 'flapp' ? filteredApps[i].flAppAndroidId : filteredApps[i].rnAppAndroidId));
                    if (androidData) {
                        Constants.LIST_APP_METRICS.forEach(metric => {
                            updateAppsAggregateItemInResult(appType, filteredApps[i].name, metric.value + '_android', androidData[metric.value]);
                        });
                    }
                }
            });
        for (let i = 0; i < filter.flapps.length; i++) {
            axios.get(Constants.API_ROOT_URL + '/app-traffic/get-metrics-with-dimensions?streamId=' + filter.flapps[i].flAppAndroidId + '&startDate=' + filter.startDate + '&endDate=' + filter.endDate)
                .then(response => {
                    updateFLAppDayByDayResult(filter.flapps[i].name, response.data)
                });
        }
    }

    const fetchAppsRevenue = appType => {
        axios.post('https://dev-dot-micro-enigma-235001.appspot.com/dataapi?type=get-in-app-purchase', {
            startTime: convertDateToTimestamp(filter.startDate),
            endTime: convertDateToTimestamp(filter.endDate),
            appIds: filter.flapps.map(cat => cat.appId),
        }).then(response => {
            filter.flapps.forEach(cat => {
                let revenueAndroid = 0, revenueIos = 0;
                for (let j = 0; j < response.data[cat.appId].length; j++) {
                    if (response.data[cat.appId][j].osType === 'ios') {
                        revenueIos += response.data[cat.appId][j].price;
                    } else {
                        revenueAndroid += response.data[cat.appId][j].price;
                    }
                }
                updateAppsRevenue(appType, cat.name, {
                    iap_revenue_ios: parseInt(revenueIos),
                    iap_revenue_android: parseInt(revenueAndroid),
                });
            })
        });

        axios.get(Constants.API_ROOT_URL + '/app/ads-revenue?startDate=' + filter.startDate + '&endDate=' + filter.endDate)
            .then(response => {
                for (let i = 0; i < filter.flapps.length; i++) {
                    const iosRevenue = response.data.find(app => app.appID === filter.flapps[i].flAppIosId);
                    if (iosRevenue) {
                        updateAppsRevenue(appType, filter.flapps[i].name, {
                            ads_revenue_ios: parseInt(iosRevenue.adsRevenue),
                        });
                    }
                    const androidRevenue = response.data.find(app => app.appID === filter.flapps[i].flAppAndroidId);
                    if (androidRevenue) {
                        updateAppsRevenue(appType, filter.flapps[i].name, {
                            ads_revenue_android: parseInt(androidRevenue.adsRevenue),
                        });
                    }
                }
            });
    }

    const fetchAppsLearnAnswersStatistic = cat => {
        axios.get(Constants.API_ROOT_URL + '/app/learn-answers?startDate=' + filter.startDate + '&endDate=' + filter.endDate + '&appType=flapp&appID=' + cat.appId)
            .then(response => {
                console.log(response.data);
                updateLearnAnswers(cat.name, response.data);
            });
    }

    const fetchAppsTestAnswersStatistic = cat => {
        axios.get(Constants.API_ROOT_URL + '/app/test-answers?startDate=' + filter.startDate + '&endDate=' + filter.endDate + '&appType=flapp&appID=' + cat.appId)
            .then(response => {
                console.log(response.data);
                updateTestAnswers(cat.name, response.data);
            });
    }

    return (
        <div className='filters'>
            <div>
                <SavedFilters />
            </div>
            <div>
                <div className='filter-container'>
                    <DropdownFilter
                        listCat={Constants.CATEGORY.filter(cat => {
                            if (cat.webId !== undefined) {
                                if (userPermissions.length === 0) {
                                    return true;
                                }
                                return userPermissions.find(test => test.appID == cat.appId) !== undefined;
                            }
                            return false;
                        })}
                        title='Web'
                        type='web'
                    />
                </div>
                <div className='filter-container'>
                    <DropdownFilter
                        listCat={
                            Constants.CATEGORY.filter(cat => {
                                if (cat.flAppAndroidId !== undefined || cat.flAppIosId !== undefined) {
                                    if (userPermissions.length === 0) {
                                        return true;
                                    }
                                    return userPermissions.find(test => test.appID == cat.appId) !== undefined;
                                }
                                return false;
                            })}
                        title='Flutter App'
                        type='flapp'
                    />
                </div>
                {/* <div className='filter-container'>
                    <DropdownFilter
                        listCat={Constants.CATEGORY
                            .filter(cat => cat.rnAppAndroidId !== undefined || cat.rnAppIosId !== undefined)}
                        title='RN App'
                        type='rnapp'
                    />
                </div> */}
                <div className='filter-container'>
                    <TimeFilter />
                </div>
                <Button onClick={fetchData} id='btn-filter'>Filter</Button>
            </div>
        </div>
    );
}

function mapStateToProps(state, props) {
    return {
        filter: state.filterReducer,
        userPermissions: state.userState.permissions,
    }
}

export default connect(mapStateToProps, {
    updateWebsResult,
    updateWebsDayByDayResult,
    updateWebsAggregateItemInResult,
    updateWebsRevenue,
    updateAppsResult,
    updateAppsAggregateItemInResult,
    updateFLAppDayByDayResult,
    updateAppsRevenue,
    updateLearnAnswers,
    updateTestAnswers,
})(Filter);