import React from "react";
import Collapsible from "react-collapsible";
import DisplayQuestionList from "./DisplayQuestionsList";
import Pagination from './Pagination';

const Collapse = (props) => {
  const checkPage = (page) => {
    if (page === undefined || page.length === 0) return true;
    return false;
  };
  const {
    triggerTitle,
    problemList,
    currentPageData,
    PER_PAGE,
    handlePage,
    handleModalOpen,
  } = props;
  return (
    <Collapsible
      trigger={triggerTitle + ` ${problemList.length} vấn đề`}
      triggerDisabled={checkPage(currentPageData)}
    >
      <DisplayQuestionList
        questionList={currentPageData}
        handleModalOpen={handleModalOpen}
      />
      <Pagination
        PER_PAGE={PER_PAGE}
        questions={problemList}
        handlePage={handlePage}
      />
    </Collapsible>
  );
};

export default Collapse;
