export const updateWebsResult = data => ({
    type: 'UPDATE_WEB_RESULT',
    payload: data,
});

export const updateWebsAggregateResult = (catName, data) => ({
    type: 'UPDATE_WEB_AGGREGATE_RESULT',
    payload: data,
    catName,
});

export const updateWebsAggregateItemInResult = (catName, key, value) => ({
    type: 'UPDATE_WEB_AGGREGATE_ITEM_IN_RESULT',
    catName,
    key,
    value,
});

export const updateWebsDayByDayResult = (catName, data) => ({
    type: 'UPDATE_WEB_DAY_BY_DAY_RESULT',
    payload: data,
    catName,
});

export const updateWebsRevenue = (catName, revenueType, revenue) => ({
    type: 'UPDATE_WEB_REVENUE',
    catName,
    revenueType,
    revenue
});

export const updateAppsResult = (appType, apps) => ({
    type: 'UPDATE_' + (appType === 'flapp' ? 'FL' : 'RN') + '_APPS_RESULT',
    payload: apps,
});

export const updateAppsAggregateItemInResult = (appType, catName, key, value) => ({
    type: 'UPDATE_' + (appType === 'flapp' ? 'FL' : 'RN') + '_APPS_AGGREGATE_ITEM_IN_RESULT',
    catName,
    key,
    value,
});

export const updateFLAppDayByDayResult = (catName, data) => ({
    type: 'UPDATE_FL_APP_DAY_BY_DAY_RESULT',
    payload: data,
    catName,
});

export const updateAppsRevenue = (appType, catName, revenue) => ({
    type: 'UPDATE_' + (appType === 'flapp' ? 'FL' : 'RN') + '_APPS_REVENUE',
    catName,
    revenue
});

export const updateLearnAnswers = (catName, data) => ({
    type: 'UPDATE_LEARN_ANSWERS',
    catName,
    payload: data
});

export const updateTestAnswers = (catName, data) => ({
    type: 'UPDATE_TEST_ANSWERS',
    catName,
    payload: data
});