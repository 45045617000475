import {useState, useEffect} from 'react';
import {ResponsiveContainer, Cell, Bar, XAxis, YAxis, Tooltip, BarChart, ComposedChart, Line} from 'recharts';
import {formatNumber, lightenDarkenColor} from '../../../utils/utils';
import { Tabs, Tab } from 'react-bootstrap';

const LearnTestAnswersChart = ({result, catList, type}) => {
    const [selectedCats, setSelectedCats] = useState(undefined);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (selectedCats === undefined) {
            setSelectedCats(catList);
        }
        const catType = [type + 'Answers'];
        setData(result.filter(cat => cat[catType] && cat[catType].nbAnswersPerSession).map(cat => {
            let catData = {
                ...cat,
                ...cat[catType],
                nbAnswersPerUserMinusSessions: cat[catType].nbAnswersPerUser - cat[catType].nbAnswersPerSession,
                wrongAnswerRate: 100 - cat[catType].correctAnswerRate,
            };
            if (type === 'test') {
                catData['finishTestRateInChart'] = catData.testAnswers.userDoesTestRate * catData.testAnswers.finishTestRate / 100;
                catData['userDoesTestRateInChart'] = catData.testAnswers.userDoesTestRate - catData['finishTestRateInChart'];
            }
            console.log(catData);
            return catData;
        }));
    }, [result]);

    const AnswersPerSessionUserTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length > 0) {
            return (
                <div className='custom-tooltip'>
                    <div className='custom-tooltip-label'>{label}</div>
                    <div className='custom-tooltip-total' style={{color: lightenDarkenColor(payload[0]['payload']['color'], 50)}}>
                        Number answers per user: {payload[1]['payload']['nbAnswersPerUser']}
                    </div>
                    <div className='custom-tooltip-value' style={{color: payload[0]['payload']['color']}}>
                        Number answers per session: {payload[0]['value']}
                    </div>
                    <div className='custom-tooltip-value' style={{color: '#333'}}>
                        {payload[0]['payload']['usersAnswerMoreThanAvgRate']}% users answer more than {payload[0]['payload']['nbAnswersPerUser']} questions
                    </div>
                </div>
            );
        }

        return null;
    }

    const CorrectWrongAnswersTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length > 0) {
            return (
                <div className='custom-tooltip'>
                    <div className='custom-tooltip-label'>{label}</div>
                    <div className='custom-tooltip-total' style={{color: payload[0]['payload']['color']}}>
                        Correct Answer: {formatNumber(payload[0]['value'])}%
                    </div>
                </div>
            );
        }

        return null;
    }

    const StartFinishTestTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length > 0) {
            return (
                <div className='custom-tooltip'>
                    <div className='custom-tooltip-label'>{label}</div>
                    <div className='custom-tooltip-total' style={{color: payload[0]['payload']['color']}}>
                        {formatNumber(payload[0]['payload']['userDoesTestRate'])}% users start test at least once
                    </div>
                    <div className='custom-tooltip-total' style={{color: payload[0]['payload']['color']}}>
                        Among them, {formatNumber(payload[0]['payload']['finishTestRate'])}% finish the test
                    </div>
                </div>
            );
        }

        return null;
    }

    const AVGTestScoreTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length > 0) {
            return (
                <div className='custom-tooltip'>
                    <div className='custom-tooltip-label'>{label}</div>
                    <div className='custom-tooltip-total' style={{color: payload[0]['payload']['color']}}>
                        Điểm trung bình: {parseInt(payload[0]['value'])}/100
                    </div>
                    <div className='custom-tooltip-value' style={{color: payload[0]['payload']['color']}}>
                        {parseInt(payload[1]['value'])}% người (đã bắt đầu test) có điểm cao hơn trung bình
                    </div>
                    <div className='custom-tooltip-value' style={{color: payload[0]['payload']['color']}}>
                        {parseInt(payload[2]['value'])}% người (đã submit test) có điểm cao hơn trung bình
                    </div>
                </div>
            );
        }

        return null;
    }

    return (
        <div className='chart-panel'>
            <div className='panel-type'>{type}</div>
            <Tabs defaultActiveKey='session_user' variant='tabs'>
                <Tab eventKey='session_user' title={'Answers per session/user'}>
                    {selectedCats && (
                        <div className='chart-container'>
                            <div>
                                <ResponsiveContainer height={400} width='100%'>
                                    <ComposedChart data={data}>
                                        <Bar dataKey="nbAnswersPerSession" stackId="a" fill="red" yAxisId="left">
                                            {
                                                data.map((entry, index) => {
                                                    return <Cell fill={data[index].color} key={index} />;
                                                })
                                            }
                                        </Bar>
                                        <Bar dataKey="nbAnswersPerUserMinusSessions" stackId="a" yAxisId="left" radius={[10, 10, 0, 0]}>
                                            {
                                                data.map((entry, index) => {
                                                    return <Cell fill={lightenDarkenColor(data[index].color, 50)} key={index} />;
                                                })
                                            }
                                        </Bar>
                                        <Line dataKey="usersAnswerMoreThanAvgRate" stroke="#ff7300" type="monotone" yAxisId="right" />
                                        <XAxis dataKey="name" padding={{ left: 10 }} />
                                        <YAxis yAxisId="left" />
                                        <YAxis yAxisId="right" orientation="right" domain={[0, 100]} />
                                        <Tooltip content={<AnswersPerSessionUserTooltip />} cursor={{fill: 'rgba(0, 0, 0, 0)'}} />
                                    </ComposedChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    )}
                </Tab>
                <Tab eventKey='correct_wrong' title={'Correct answers rate'}>
                    {selectedCats && (
                        <div className='chart-container'>
                            <div>
                                <ResponsiveContainer height={400} width='100%'>
                                    <BarChart data={data}>
                                        <Bar dataKey="correctAnswerRate" stackId="a" fill="red" yAxisId="left" radius={[10, 10, 0, 0]}>
                                            {
                                                data.map((entry, index) => {
                                                    return <Cell fill={data[index].color} key={index} />;
                                                })
                                            }
                                        </Bar>
                                        <XAxis dataKey="name" padding={{ left: 10 }} />
                                        <YAxis yAxisId="left" domain={[0, 100]} />
                                        <Tooltip content={<CorrectWrongAnswersTooltip />} cursor={{fill: 'rgba(0, 0, 0, 0)'}} />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    )}
                </Tab>
                {type === 'test' && (
                    <Tab eventKey='start_finish_test' title={'Start/Finish Test'}>
                        {selectedCats && (
                            <div className='chart-container'>
                                <div>
                                    <ResponsiveContainer height={400} width='100%'>
                                        <ComposedChart data={data}>
                                            <Bar dataKey="finishTestRateInChart" stackId="a" yAxisId="left" fill="green" />
                                            <Bar dataKey="userDoesTestRateInChart" stackId="a" yAxisId="left" radius={[10, 10, 0, 0]}>
                                                {
                                                    data.map((entry, index) => {
                                                        return <Cell fill={data[index].color} key={index} />;
                                                    })
                                                }
                                            </Bar>
                                            <XAxis dataKey="name" padding={{ left: 10 }} />
                                            <YAxis yAxisId="left" />
                                            <Tooltip content={<StartFinishTestTooltip />} cursor={{fill: 'rgba(0, 0, 0, 0)'}} />
                                        </ComposedChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        )}
                    </Tab>
                )}
                {type === 'test' && (
                    <Tab eventKey='avg_test_score' title={'AVG Test Score'}>
                        {selectedCats && (
                            <div className='chart-container'>
                                <div>
                                    <ResponsiveContainer height={400} width='100%'>
                                        <ComposedChart data={data}>
                                            <Bar dataKey="avgScore" stackId="a" yAxisId="left" radius={[10, 10, 0, 0]}>
                                                {
                                                    data.map((entry, index) => {
                                                        return <Cell fill={data[index].color} key={index} />;
                                                    })
                                                }
                                            </Bar>
                                            <Line dataKey="nbUsersGreaterThanAvgScoreRate" stroke="#ff7300" type="monotone" yAxisId="right" />
                                            <Line dataKey="nbFinishedTestUsersGreaterThanAvgScoreRate" stroke="#00ff00" type="monotone" yAxisId="right" />
                                            <XAxis dataKey="name" padding={{ left: 10 }} />
                                            <YAxis yAxisId="left" domain={[0, 100]} />
                                            <YAxis yAxisId="right" orientation="right" domain={[0, 100]} />
                                            <Tooltip content={<AVGTestScoreTooltip />} cursor={{fill: 'rgba(0, 0, 0, 0)'}} />
                                        </ComposedChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        )}
                    </Tab>
                )}
            </Tabs>
        </div>
    );
}

export default LearnTestAnswersChart;