import { Row, Col } from 'react-bootstrap/';
import Constants from '../../utils/constants';
import { connect } from 'react-redux';
import './styles.scss';
import TrafficChart from './TrafficChart';
import RevenueChart from './RevenueChart';
import LearnTestAnswersChart from './LearnTestAnswersChart';
import QuestionQuality from '../question-quality';

const Result = ({ webResult, flappResult, filter }) => {
    if (filter.activeSegment === 'traffic_revenue') {
        return (
            <Row className='result-container'>
                {webResult.length > 0 && (
                    <>
                    <Col md={6}>
                        <TrafficChart type='web' result={webResult} metricList={Constants.LIST_WEB_METRICS}
                            catList={filter.webs.map(cat => cat.name)} />
                    </Col>
                    <Col md={6}>
                        <RevenueChart type='web' result={webResult} catList={filter.webs.map(cat => cat.name)} />
                    </Col>
                    </>
                )}
                {flappResult.length > 0 && (
                    <>
                    <Col md={6}>
                        <TrafficChart type='flapp' result={flappResult} metricList={Constants.LIST_APP_METRICS}
                            catList={filter.flapps.map(cat => cat.name)} />
                    </Col>
                    <Col md={6}>
                        <RevenueChart type='flapp' result={flappResult} catList={filter.flapps.map(cat => cat.name)} />
                    </Col>
                    </>
                )}
            </Row>
        );
    } else if (filter.activeSegment === 'product_quality') {
        return (
            <Row className='result-container'>
                <Col md={6}>
                    <LearnTestAnswersChart result={flappResult} type='learn' catList={filter.flapps.map(cat => cat.name)} />
                </Col>
                <Col md={12}>
                    <LearnTestAnswersChart result={flappResult} type='test' catList={filter.flapps.map(cat => cat.name)} />
                </Col>
            </Row>
        )
    } else if (filter.activeSegment === 'question_quality') {
        return (
            <QuestionQuality />
        );
    }
    return null;
}

function mapStateToProps(state, props) {
    return {
        webResult: state.resultReducer.webs,
        flappResult: state.resultReducer.flapps,
        filter: state.filterReducer,
    }
}

export default connect(mapStateToProps, { })(Result);