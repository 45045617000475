import { ITest } from "../../models/test";

export interface UserAction {
    type: UserActionType;
    permissions: ITest[];
}

export enum UserActionType {
    SET_USER_PERMISSIONS = "SET_USER_PERMISSIONS",
}

export const setUserPermissions = (permissions: any[]) => {
    return {
        type: UserActionType.SET_USER_PERMISSIONS,
        permissions
    }
}