export default class Constants {
    // static API_ROOT_URL = 'http://localhost:8000';
    static API_ROOT_URL = "https://dashboard-api2.abc-elearning.org";


    static HTTP_REQUEST_SUCCESS = 200;
    static URL_INFO_EVENT = Constants.API_ROOT_URL + "/info/eventByCategory";
    static URL = Constants.API_ROOT_URL + "/web/event";
    static URL_APP_EVENT = Constants.API_ROOT_URL + "/app/event";
    static URL_APP_EVENT_BY_DATE = Constants.API_ROOT_URL + "/app/eventByDate";
    static URL_ALL_EVENT = Constants.API_ROOT_URL + "/web/allEvent";
    static URL_WEB_APP_INFO = Constants.API_ROOT_URL + "/info/appWeb";
    static URL_WEB_STATUS = Constants.API_ROOT_URL + "/api/web-status-data";
    //Gummy
    static URL_GUMMY_EDIT_PROFILE = Constants.API_ROOT_URL + "/gummy/editProfile";
    static URL_GUMMY_LAST_ACTION = Constants.API_ROOT_URL + "/gummy/lastAction";
    static URL_GUMMY_PLAY_PER_SESSION =
        Constants.API_ROOT_URL + "/gummy/playPerSession";
    static URL_GUMMY_TOTAL_PLAY_GAME =
        Constants.API_ROOT_URL + "/gummy/totalPlayGame";
    static TIMESTAMP_DATE = 1000 * 60 * 60 * 24;
    static TYPE_TABLE_TRAFFIC = 1;
    static TYPE_TABLE_REVENUE = 2;
    static TYPE_TABLE_EVENTS = 3;
    static TYPE_TABLE_CONTENT = 4;
    static LIST_FILTER_NAME_LOCALSTORAGE = "list-filter-name-fix-1";
    static MAX_TIME = new Date(new Date().getTime() - Constants.TIMESTAMP_DATE);
    static TYPE_CATEGORY_WEB = 1;
    static TYPE_CATEGORY_APP_FLUTTER = 2;
    static TYPE_CATEGORY_APP_RN = 3;
    static METRIC_HAVE_SUBTITLE = ""
    //initial Data And Label
    static BACKGROUND_COLOR_CHART = [
        "rgb(255, 99, 132)",
        "rgb(54, 162, 235)",
        "rgb(75, 192, 192)",
    ];
    static LIST_NA_IOS = ["Uninstall app", "Churn Rate (Uninstall Rate)"]
    static CATEGORY = [
        {
            name: 'Passemall',
            type: [Constants.TYPE_CATEGORY_WEB],
            webId: '219502380',
            color: '#15AB68',
            hasAds: true,
            slug: 'passemall',
        },
        {
            name: 'ASVAB',
            appId: 4878338973761536,
            type: [Constants.TYPE_CATEGORY_WEB, Constants.TYPE_CATEGORY_APP_FLUTTER],
            webId: '233499836',
            flAppAndroidId: '1541823121',
            flAppIosId: '1541841966',
            color: '#8A8862',
            hasAds: true,
            slug: 'asvab',
        },
        {
            name: 'CDL',
            appId: 5513556919320576,
            type: [
                Constants.TYPE_CATEGORY_WEB,
                Constants.TYPE_CATEGORY_APP_FLUTTER,
                Constants.TYPE_CATEGORY_APP_RN,
            ],
            webId: '233539277',
            flAppAndroidId: '1541835138',
            flAppIosId: '1541841977',
            rnAppAndroidId: '2726673757',
            rnAppIosId: '2726674910',
            color: '#737E8E',
            appStoreID: '1469003902',
            hasAds: true,
            slug: 'cdl',
        },
        {
            name: 'GED Test',
            appId: 5296397775536128,
            type: [Constants.TYPE_CATEGORY_WEB, Constants.TYPE_CATEGORY_APP_FLUTTER],
            webId: '233542505',
            flAppAndroidId: '1541826034',
            flAppIosId: '1836507743',
            color: '#174D7C',
            slug: 'ged',
        },
        {
            name: 'TEAS',
            appId: 5186025303310336,
            type: [Constants.TYPE_CATEGORY_WEB, Constants.TYPE_CATEGORY_APP_FLUTTER],
            webId: '233524673',
            flAppAndroidId: '1541820250',
            flAppIosId: '1738198535',
            color: '#737E8E',
            slug: 'teas',
        },
        {
            name: 'CNA',
            appId: 5747415311187968,
            type: [Constants.TYPE_CATEGORY_WEB, Constants.TYPE_CATEGORY_APP_FLUTTER],
            webId: '243224419',
            flAppAndroidId: '1780000506',
            flAppIosId: '1841653020',
            color: '#4E63BD',
            slug: 'cna',
        },
        {
            name: 'AWS',
            appId: 5840808850751488,
            type: [Constants.TYPE_CATEGORY_WEB, Constants.TYPE_CATEGORY_APP_FLUTTER],
            webId: '243229769',
            flAppAndroidId: '2036032112',
            flAppIosId: '2036991417',
            color: '#00AF81',
            slug: 'aws',
        },
        {
            name: 'PMP',
            appId: 5738309204574208,
            type: [Constants.TYPE_CATEGORY_WEB, Constants.TYPE_CATEGORY_APP_FLUTTER],
            webId: '243678259',
            flAppAndroidId: '1601211332',
            flAppIosId: '1841674262',
            color: '#E3844C',
            slug: 'pmp',
        },
        {
            name: 'PTCE',
            appId: 5713977044631552,
            type: [Constants.TYPE_CATEGORY_WEB, Constants.TYPE_CATEGORY_APP_FLUTTER],
            webId: '243624768',
            flAppAndroidId: '1601178612',
            flAppIosId: '1839561924',
            color: '#06253A',
            slug: 'ptce',
        },
        {
            name: 'Real Estate',
            appId: 6141832157200384,
            type: [Constants.TYPE_CATEGORY_WEB, Constants.TYPE_CATEGORY_APP_FLUTTER],
            webId: '243631953',
            flAppAndroidId: '1601234335',
            flAppIosId: '1841676825',
            color: '#DABE8F',
            slug: 'real_estate',
        },
        {
            name: 'Servsafe',
            appId: 5748957795844096,
            type: [Constants.TYPE_CATEGORY_WEB, Constants.TYPE_CATEGORY_APP_FLUTTER],
            webId: '243656305',
            flAppAndroidId: '2019474755',
            color: '#E3844C',
            slug: 'servsafe',
        },
        {
            name: 'Drivingtheory',
            appId: 5708468430307328,
            type: [Constants.TYPE_CATEGORY_WEB, Constants.TYPE_CATEGORY_APP_FLUTTER],
            webId: '243677343',
            flAppAndroidId: '1541830307',
            flAppIosId: '1841644712',
            color: '#3A5A82',
            slug: 'driving_theory',
        },
        {
            name: 'DMV',
            appId: 5082322646859776,
            type: [Constants.TYPE_CATEGORY_WEB, Constants.TYPE_CATEGORY_APP_FLUTTER],
            webId: '243632964',
            flAppAndroidId: '1541842345',
            flAppIosId: '1541827439',
            color: '#1C7BBE',
            slug: 'dmv',
        },
        {
            name: 'ACCUPLACER',
            appId: 6425502466179072,
            type: [Constants.TYPE_CATEGORY_APP_FLUTTER],
            flAppAndroidId: '1541821459',
            flAppIosId: '1841644692',
            color: '#0eb8f2',
            appStoreID: '1502050400',
            slug: 'accuplacer',
        },
        {
            name: 'CEH',
            appId: 5704077698662400,
            type: [Constants.TYPE_CATEGORY_APP_FLUTTER],
            flAppAndroidId: '1926011144',
            flAppIosId: '2014896621',
            color: '#031b6e',
            slug: 'ceh',
        },
        {
            name: 'CFA',
            appId: 5759481505906688,
            type: [Constants.TYPE_CATEGORY_APP_FLUTTER],
            flAppAndroidId: '2019456170',
            flAppIosId: '2122238021',
            color: '#45c1b8',
            slug: 'cfa',
        },
        {
            name: 'CompTIA A+',
            appId: 5655480462475264,
            type: [Constants.TYPE_CATEGORY_APP_FLUTTER],
            flAppAndroidId: '1601212611',
            flAppIosId: '1836468830',
            color: '#f23126',
            slug: 'comptiaa',
        },
        {
            name: 'CompTIA Network+',
            appId: 5653852602761216,
            type: [Constants.TYPE_CATEGORY_APP_FLUTTER],
            flAppAndroidId: '1779985283',
            flAppIosId: '1839512888',
            color: '#f23126',
            slug: 'comptianetwork',
        },
        {
            name: 'CompTIA Security+',
            appId: 5712018204000256,
            type: [Constants.TYPE_CATEGORY_APP_FLUTTER],
            flAppAndroidId: '1780000670',
            flAppIosId: '1839535957',
            color: '#f23126',
            slug: 'comptiasecurity',
        },
        {
            name: 'EMT-B',
            appId: 5711380900478976,
            type: [Constants.TYPE_CATEGORY_APP_FLUTTER],
            flAppAndroidId: '1779993599',
            flAppIosId: '1841670566',
            color: '#4745ff',
            slug: 'emt',
        },
        {
            name: 'EPA 608',
            appId: 5645825162084352,
            type: [Constants.TYPE_CATEGORY_APP_FLUTTER],
            flAppAndroidId: '2019473893',
            color: '#3a7dcc',
            slug: 'epa',
        },
        {
            name: 'HESI A2',
            appId: 5657969412800512,
            type: [Constants.TYPE_CATEGORY_APP_FLUTTER],
            flAppAndroidId: '1779996844',
            flAppIosId: '1841666145',
            color: '#4493e3',
            slug: 'hesi',
        },
        {
            name: 'HVAC',
            appId: 5656255221727232,
            type: [Constants.TYPE_CATEGORY_APP_FLUTTER],
            flAppAndroidId: '2019481641',
            flAppIosId: '2122230434',
            color: '#3a7dcc',
            slug: 'hvac',
        },
        {
            name: 'MBLEX',
            appId: 5678215016218624,
            type: [Constants.TYPE_CATEGORY_APP_FLUTTER],
            flAppAndroidId: '1841670153',
            flAppIosId: '1841634731',
            color: '#0fa8d2',
            slug: 'mblex',
        },
        {
            name: 'Motorcycle',
            appId: 5685506780168192,
            type: [Constants.TYPE_CATEGORY_APP_FLUTTER],
            flAppAndroidId: '1677943077',
            flAppIosId: '1841661028',
            color: '#1C7BBE',
            slug: '',
        },
        {
            name: 'NATE',
            appId: 6667648976814080,
            type: [Constants.TYPE_CATEGORY_APP_FLUTTER],
            flAppAndroidId: '2036058006',
            flAppIosId: '2036995869',
            color: '#50ddc4',
            slug: 'nate',
        },
        {
            name: 'NCLEX-PN',
            appId: 5651471211167744,
            type: [Constants.TYPE_CATEGORY_APP_FLUTTER],
            flAppAndroidId: '1779975419',
            flAppIosId: '1841670416',
            color: '#027dc8',
            slug: 'nclexpn',
        },
        {
            name: 'NCLEX-RN',
            appId: 5046838600138752,
            type: [Constants.TYPE_CATEGORY_APP_FLUTTER],
            flAppAndroidId: '1779999033',
            flAppIosId: '1841671837',
            color: '#0fa8d2',
            slug: 'nclexrn',
        },
        {
            name: 'DKT',
            appId: 5746311907246080,
            type: [Constants.TYPE_CATEGORY_APP_FLUTTER],
            flAppAndroidId: '1541828687',
            flAppIosId: '1841675849',
            color: '#ffe600',
            slug: 'dkt',
        },
        {
            name: 'G1',
            appId: 5681717746597888,
            type: [
                Constants.TYPE_CATEGORY_APP_FLUTTER,
                Constants.TYPE_CATEGORY_APP_RN,
            ],
            flAppAndroidId: '1541801949',
            flAppIosId: '1841634494',
            rnAppAndroidId: '2704005184',
            rnAppIosId: '2704002024',
            color: '#fe292a',
            slug: 'ontariog1',
        },
        {
            name: 'PHR',
            appId: 5736441166430208,
            type: [Constants.TYPE_CATEGORY_APP_FLUTTER],
            flAppAndroidId: '1779994118',
            flAppIosId: '1841671010',
            color: '#ec1e45',
            slug: 'phr',
        },
        {
            name: 'CCNA',
            appId: 4516085158117376,
            type: [Constants.TYPE_CATEGORY_APP_FLUTTER],
            flAppAndroidId: '2771132924',
            flAppIosId: '1841671010',
            color: '#015468',
            appStoreID: '1583152765',
            slug: 'ccna',
        },
    ];

    static LIST_TYPE = [
        { value: "webEvent", name: "Web" },
        { value: "appEvent", name: "App" },
        { value: "gummy", name: "Gummy" },
        { value: "tests-hub", name: "Tests hub" },
    ];

    static LIST_WEB_METRICS = [
        {
            label: 'Active Users',
            value: 'ga:users',
            aggregateLabel: 'Total Result',
        },
        {
            label: 'Sessions',
            value: 'ga:sessions',
            aggregateLabel: 'Total Result',
        },
        {
            label: 'Avg Session Duration',
            value: 'ga:avgSessionDuration',
            aggregateLabel: 'Average Result',
        },
        {
            label: 'Bounce Rate',
            value: 'ga:bounceRate',
            aggregateLabel: 'Average Result',
        },
        {
            label: 'Pages per session',
            value: 'ga:pageviewsPerSession',
            aggregateLabel: 'Average Result',
        },
        {
            label: 'Returning users',
            value: 'returningUsers',
            aggregateLabel: 'Average',
            route: '/web-traffic/get-returning-users',
        },
    ];

    static LIST_APP_METRICS = [
        {
            label: 'Active Users',
            value: 'activeUsers',
            aggregateLabel: 'Total Result',
        },
        {
            label: 'Avg Session Duration',
            value: 'avgSessionDuration',
            aggregateLabel: 'Average Result',
        }
    ];

    static LIST_EVENT_OBJECT = [
        {
            valueWeb: "ga:users",
            valueApp: "activeUsers",
            name: "Active Users",
            type: [
                Constants.TYPE_CATEGORY_APP_FLUTTER,
                Constants.TYPE_CATEGORY_APP_RN,
                Constants.TYPE_CATEGORY_WEB,
            ],
            typeValueTable: "sum",
            typeValueFormat: "number",
            typeTable: Constants.TYPE_TABLE_TRAFFIC,
        },
        {
            valueWeb: "ga:sessions",
            name: "Sessions",
            type: [Constants.TYPE_CATEGORY_WEB],
            typeValueTable: "sum",
            typeValueFormat: "number",
            typeTable: Constants.TYPE_TABLE_TRAFFIC,
        },
        {
            valueWeb: "ga:pageviewsPerSession",
            name: "Number Pages per session",
            type: [Constants.TYPE_CATEGORY_WEB],
            typeValueTable: "avg",
            typeValueFormat: "number",
            typeTable: Constants.TYPE_TABLE_TRAFFIC,
        },
        {
            valueWeb: "ga:returnUsers",
            name: "Number returning Users",
            type: [
                Constants.TYPE_CATEGORY_WEB,
            ],
            typeValueTable: "sum",
            typeValueFormat: "number",
            typeTable: Constants.TYPE_TABLE_TRAFFIC,
        },
        {
            valueWeb: "ga:bounceRate",
            valueApp: "engagementRate",
            name: "Bounce Rate",
            type: [
                Constants.TYPE_CATEGORY_WEB,
            ],
            typeValueTable: "avg",
            typeValueFormat: "percent",
            typeTable: Constants.TYPE_TABLE_TRAFFIC,
        },
        {
            valueWeb: "ga:avgSessionDuration",
            valueApp: "userEngagementDuration",
            name: "Average Time Per Session",
            type: [
                Constants.TYPE_CATEGORY_WEB,
                Constants.TYPE_CATEGORY_APP_FLUTTER,
                Constants.TYPE_CATEGORY_APP_RN,
            ],
            typeValueTable: "avg",
            typeValueFormat: "time",
            typeTable: Constants.TYPE_TABLE_TRAFFIC,
        },
        {
            name: "Total Revenue",
            valueApp: "totalRevenue",
            type: [
                Constants.TYPE_CATEGORY_APP_FLUTTER,
                // Constants.TYPE_CATEGORY_APP_RN,
            ],
            typeValueTable: "sum",
            typeValueFormat: "$",
            typeTable: Constants.TYPE_TABLE_REVENUE,
        },
        {
            name: "Purchase Revenue",
            valueApp: "purchaseRevenue",
            type: [
                Constants.TYPE_CATEGORY_APP_FLUTTER,
            ],
            typeValueTable: "sum",
            typeValueFormat: "$",
            typeTable: Constants.TYPE_TABLE_REVENUE,
        },
        {
            name: "Total Ads Revenue",
            valueApp: "totalAdRevenue",
            valueWeb: "ga:transactionRevenue",
            type: [
                // Constants.TYPE_CATEGORY_WEB,
                Constants.TYPE_CATEGORY_APP_FLUTTER,
                // Constants.TYPE_CATEGORY_APP_RN,
            ],
            typeValueTable: "sum",
            typeValueFormat: "$",
            typeTable: Constants.TYPE_TABLE_REVENUE,
        },
        {
            valueWeb: "ga:adClicks",
            valueApp: "publisherAdClicks",
            name: "Ads Clicks",
            type: [
                Constants.TYPE_CATEGORY_APP_FLUTTER,
                // Constants.TYPE_CATEGORY_WEB,
                // Constants.TYPE_CATEGORY_APP_RN,
            ],
            typeValueTable: "sum",
            typeValueFormat: "number",
            typeTable: Constants.TYPE_TABLE_REVENUE,
        },
        {
            valueWeb: "ga:rpm",
            valueApp: "ga4:rpm",
            name: "Ads RPM",
            type: [
                Constants.TYPE_CATEGORY_APP_FLUTTER,
                // Constants.TYPE_CATEGORY_WEB,
                // Constants.TYPE_CATEGORY_APP_RN,
            ],
            typeValueTable: "sum",
            typeValueFormat: "$",
            typeTable: Constants.TYPE_TABLE_REVENUE,
        },
        {
            valueWeb: "ga:impressions",
            valueApp: "publisherAdImpressions",
            name: "Ads Impressions",
            type: [
                Constants.TYPE_CATEGORY_APP_FLUTTER,
                // Constants.TYPE_CATEGORY_WEB,
                // Constants.TYPE_CATEGORY_APP_RN,
            ],
            typeValueTable: "sum",
            typeValueFormat: "number",
            typeTable: Constants.TYPE_TABLE_REVENUE,
        },
        {
            valueApp: "first_open",
            name: "Install app",
            type: [
                Constants.TYPE_CATEGORY_APP_FLUTTER,
                Constants.TYPE_CATEGORY_APP_RN,
            ],
            typeValueTable: "sum",
            typeValueFormat: "number",
            typeTable: Constants.TYPE_TABLE_EVENTS,
        },
        {
            valueApp: "app_remove",
            name: "Uninstall app",
            type: [
                Constants.TYPE_CATEGORY_APP_FLUTTER,
                Constants.TYPE_CATEGORY_APP_RN,
            ],
            typeValueTable: "sum",
            typeValueFormat: "number",
            typeTable: Constants.TYPE_TABLE_EVENTS,
        },
        {
            valueApp: "ga4:churn_rate",
            name: "Churn Rate",
            type: [
                Constants.TYPE_CATEGORY_APP_FLUTTER,
                Constants.TYPE_CATEGORY_APP_RN,
            ],
            typeValueTable: "avg",
            typeValueFormat: "percent",
            typeTable: Constants.TYPE_TABLE_EVENTS,
        },
        {
            valueApp: "ga4:unresolved_mistakes",
            valueWeb: "ga:unresolved_mistakes",
            name: "Unresolved mistakes",
            type: [
                Constants.TYPE_CATEGORY_WEB,
                Constants.TYPE_CATEGORY_APP_FLUTTER,
                Constants.TYPE_CATEGORY_APP_RN,
            ],
            typeValueTable: "sum",
            typeValueFormat: "number",
            typeTable: Constants.TYPE_TABLE_CONTENT,
        },
        {
            valueApp: "ga4:user_feedbacks",
            name: "User feedbacks",
            type: [
                Constants.TYPE_CATEGORY_APP_FLUTTER,
                Constants.TYPE_CATEGORY_APP_RN,
            ],
            typeValueTable: "sum",
            typeValueFormat: "number",
            typeTable: Constants.TYPE_TABLE_CONTENT,
        },
    ];

    static DASHBOARD_SEGMENT_LIST = [
        {
            label: 'Traffic & Revenue',
            value: 'traffic_revenue',
        },
    ];

    static LINE_CHART_DATA = [
        {
            time: "26-06",
            web: 84,
            app: 78,
        },
        {
            time: "27-06",
            web: 114,
            app: 12,
        },
        {
            time: "28-06",
            web: 141,
            app: 112,
        },
        {
            time: "29-06",
            web: 36,
            app: 68,
        },
        {
            time: "30-06",
            web: 54,
            app: 48,
        },
        {
            time: "01-07",
            web: 96,
            app: 59,
        },
        {
            time: "02-07",
            web: 57,
            app: 78,
        },
    ];
    static TABLE_DATA = [
        {
            platform: "Web",
            name: "test",
            totalEvent: "100",
            totalUser: "50",
            avgEvent: "2",
        },
    ];
    static TABLE_DATA_GUMMY = [
        {
            key: "Play Per User",
            value: 0,
        },
    ];
    static TABLE_DATA_GUMMY_LAST_ACTION = ["Play Per User", 0];
}
