import {useEffect, useState}from 'react';
import Constants from '../../utils/constants';
import axios from 'axios';
import { Container } from 'react-bootstrap';
import './styles.scss';
import { formatDate } from '../../utils/utils';

const ABTests = () => {
    const [abtests, setABTests] = useState([]);

    useEffect(() => {
        fetchAllABTests();
    }, []);

    const fetchAllABTests = async() => {
        const abtests = await axios.get(Constants.API_ROOT_URL + '/get-all-abtests');
        setABTests(abtests.data);
    };

    return (
        <Container fluid className="ab-testing-container">
            <div className="container abtests">
                {abtests.map(abtest => (
                    <div key={abtest.id} className="abtest">
                        <h3 className="abtest-title">{abtest.title}</h3>
                        <div className="abtest-meta">{abtest.testData.name} - {abtest.nbUsers} users</div>
                        <div className="abtest-meta">{formatDate(new Date(abtest.startDate), 'dd/mm/yyyy')} - {formatDate(new Date(abtest.endDate), 'dd/mm/yyyy')}</div>
                        <div className="abtest-versions">
                            {abtest.versions.map(version => (
                                <div key={version.id}>
                                    <div className="abtest-version-title">{version.title}</div>
                                    <div className="abtest-version-conversion-rate">{version.conversionRate}%</div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </Container>
    );
};
export default ABTests;
