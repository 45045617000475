export const setupFilter = data => ({
    type: 'SETUP_FILTER',
    payload: data,
});

export const updateStartDate = startDate => ({
    type: 'UPDATE_START_DATE',
    payload: startDate,
});

export const updateEndDate = endDate => ({
    type: 'UPDATE_END_DATE',
    payload: endDate,
});

export const updateSelectedWebs = webs => ({
    type: 'UPDATE_SELECTED_WEBS',
    payload: webs,
});

export const updateSelectedFLApps = apps => ({
    type: 'UPDATE_SELECTED_FL_APPS',
    payload: apps,
});

export const updateSelectedRNApps = apps => ({
    type: 'UPDATE_SELECTED_RN_APPS',
    payload: apps,
});

export const updateActiveSegment = activeSegment => ({
    type: 'UPDATE_ACTIVE_SEGMENT',
    payload: activeSegment,
});