export const formatDate = (date, format='default') => {
    var year = date.getFullYear();
    var months = date.getMonth() + 1;
    var day = date.getDate();
    var stringTime = "";
    var stringMonths = "";
    var stringDay = "";
    if (months < 10) {
        stringMonths = "0" + months.toString();
    } else {
        stringMonths = months.toString();
    }
    if (day < 10) {
        stringDay = "0" + day.toString();
    } else {
        stringDay = day.toString();
    }
    if (format === 'dd/mm/yyyy') {
        stringTime = stringDay + '/' + stringMonths + '/' + year;
    } else {
        stringTime = year + "-" + stringMonths + "-" + stringDay;
    }
    return stringTime;
};

export const formatDateFromAPI = time => {
    let year = time.substring(0, 4);
    let month = time.substring(4, 6);
    let day = time.substring(6, 8);
    return day + "/" + month + "/" + year;
};

export const formatTime = time => {
    let second = time % 60;
    let minutes = (time - second) / 60;
    return minutes.toString() + "m " + second.toString() + "s";
};

export const formatValue = (
    typeValueTable,
    value,
    listMetricLength,
    typeValueFormat,
    subValue,
) => {
    let avgValue = value / listMetricLength;
    if (typeValueTable === "avg") {
        if (typeValueFormat === "percent") {
            return avgValue.toFixed(2) + " %";
        }
        if (typeValueFormat === "time") {
            return formatTime(Math.round(avgValue));
        } else {
            return new Intl.NumberFormat().format(parseFloat(avgValue.toFixed(2)));
        }
    }
    return (
        new Intl.NumberFormat().format(parseFloat(value.toFixed(2))) + subValue ??
        ''
    );
};

export const formatTotalValue = (typeValueFormat, value) => {
    if (typeValueFormat === "percent") {
        return value.toFixed(2) + " %";
    }
    if (typeValueFormat === "time") {
        return formatTime(Math.round(value));
    }
    if (typeValueFormat === "$") {
        return new Intl.NumberFormat().format(parseFloat(value.toFixed(2))) + " " + typeValueFormat
    }
    return (
        new Intl.NumberFormat().format(parseFloat(value.toFixed(2)))
    );
}

export const formatDateToDayAndMonth = (date, hasWeekDay = false) => {
    const day = date.substr(6, 2);
    const month = date.substr(4, 2);
    const year = date.substr(0, 4);
    const weekDay = new Date(year + '-' + month + '-' + day).toLocaleDateString('en-US', {weekday: 'short'});
    if (hasWeekDay) {
        return weekDay + ' ' + day + '/' + month;
    }
    return day + '/' + month;
}

export const formatNumber = (number, metric = null, type = null) => {
    number = parseFloat(number);
    if (metric === 'ga:avgSessionDuration') {
        number = number / 60;
    }
    if (number % 1 !== 0) {
        number = number.toFixed(2);
    }
    if (type === 'label') {
        return formatResultByMetric(metric, number);
    }
    return number;
}

export const formatResultByMetric = (metric, result) => {
    if (metric === 'ga:bounceRate' || metric === 'returningUsers') {
        return result += ' %';
    } else if (metric === 'ga:avgSessionDuration') {
        return Math.floor(result) + 'm' + parseInt(60 * (result - Math.floor(result))) + 's';
    } else if (metric === 'revenue') {
        return '$' + result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const lightenDarkenColor = (color, percent) => {
    let usePound = false;

    if (color[0] == '#') {
        color = color.slice(1);
        usePound = true;
    }

    const num = parseInt(color, 16);

    let r = (num >> 16) + percent;

    if (r > 255) r = 255;
    else if  (r < 0) r = 0;

    let b = ((num >> 8) & 0x00FF) + percent;

    if (b > 255) b = 255;
    else if  (b < 0) b = 0;

    let g = (num & 0x0000FF) + percent;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}

export const convertDateToTimestamp = date => {
    let dateObj = new Date(date);
    dateObj.setHours(0);
    dateObj.setMinutes(0);
    dateObj.setSeconds(0);
    return dateObj.getTime();
}