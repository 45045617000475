import { useState, useEffect } from 'react';
import {formatResultByMetric, lightenDarkenColor} from '../../../utils/utils';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux';
import { Tooltip, XAxis, YAxis, BarChart, Bar, Line, ComposedChart, Cell, ResponsiveContainer } from 'recharts';
import './styles.scss';
import { Tabs, Tab } from 'react-bootstrap';

const RevenueChart = ({type, result, catList, startDate, endDate}) => {
    const [selectedCats, setSelectedCats] = useState(undefined);
    const [selectAllOptionEnabled, setSelectAllOptionEnabled] = useState(true);

    useEffect(() => {
        if (selectedCats === undefined) {
            setSelectedCats(catList);
        }
    }, [result]);

    const toggleSelectedCats = (cat = null) => {
        if (cat !== null) {
            if (selectedCats.indexOf(cat.name) > -1) {
                setSelectedCats(selectedCats.filter(name => name !== cat.name));
            } else {
                setSelectedCats(selectedCats.concat(cat.name));
            }
        } else if (selectAllOptionEnabled) {
            setSelectedCats([]);
            setSelectAllOptionEnabled(false);
        } else {
            setSelectedCats(catList);
            setSelectAllOptionEnabled(true);
        }
    }

    const CustomBarTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className='custom-tooltip'>
                    <div className='custom-tooltip-label'>{label}</div>
                    {payload.map((cat, index) => (
                        cat['name'] !== 'pro_total_buyers' ? (
                            <div className='custom-tooltip-value' key={index} style={{color: cat['color']}}>
                                {cat['name']}: {formatResultByMetric('revenue', cat['value'])}
                            </div>
                        ) : (
                            <div>
                                <div className='custom-tooltip-label'>Number Buyers: {cat['value']}</div>
                                {Object.keys(cat['payload']['pro_nb_buyers_by_tests']).map(test => (
                                    <div className='custom-tooltip-value'>{test}: {cat['payload']['pro_nb_buyers_by_tests'][test]}</div>
                                ))}
                            </div>
                        )
                     ))}
                    {payload.length > 1 && payload[0].name !== 'pro_revenue' && (
                        <div className='custom-tooltip-total'>
                            Total: {formatResultByMetric('revenue', payload.reduce((total, cat) => total + cat['value'], 0))}
                        </div>
                    )}
                </div>
            );
        }

        return null;
    }

    const getTotalRevenue = (revenueType) => {
        let total = 0;
        for (let i = 0; i < result.length; i++) {
            const cat = result[i];
            if (type === 'web') {
                if (cat['ads_revenue'] && (revenueType === 'ads' || revenueType === 'all')) {
                    total += cat['ads_revenue'];
                }
                if (cat['pro_revenue'] && (revenueType === 'pro' || revenueType === 'all')) {
                    total += cat['pro_revenue'];
                }
            } else {
                if (cat['iap_revenue_ios'] && (revenueType === 'pro' || revenueType === 'all')) {
                    total += cat['iap_revenue_ios'];
                }
                if (cat['iap_revenue_android'] && (revenueType === 'pro' || revenueType === 'all')) {
                    total += cat['iap_revenue_android'];
                }
                if (cat['ads_revenue_ios'] && (revenueType === 'ads' || revenueType === 'all')) {
                    total += cat['ads_revenue_ios'];
                }
                if (cat['ads_revenue_android'] && (revenueType === 'ads' || revenueType === 'all')) {
                    total += cat['ads_revenue_android'];
                }
            }
        }
        return formatResultByMetric('revenue', total);
    }

    const getLoadingStatus = () => {
        let nbLoadedCats;
        if (type === 'web') {
            nbLoadedCats = result.filter(cat => cat.ads_revenue || cat.pro_revenue).length;
        } else if (type === 'flapp') {
            nbLoadedCats = result.filter(cat => cat.ads_revenue_ios || cat.ads_revenue_android || cat.iap_revenue_ios || cat.iap_revenue_android).length;
        }
        if (nbLoadedCats < result.length) {
            return nbLoadedCats + '/' + result.length;
        }
        return '';
    }

    return (
        <div className='chart-panel'>
            <div className='panel-type'>{type} revenue <span>{getLoadingStatus()}</span></div>
            <Tabs defaultActiveKey='Total Revenue' variant='tabs'>
                <Tab eventKey='Total Revenue' title={'Total Revenue: ' + getTotalRevenue('all')}>
                    {selectedCats && (
                        <div className='chart-container'>
                            <div>
                                <ResponsiveContainer height={400} width='100%'>
                                    <BarChart data={result.filter(cat => selectedCats.indexOf(cat.name) > -1)}>
                                        {type === 'web' && (
                                            <Bar type="monotone" dataKey='ads_revenue' stackId='a'>
                                                {
                                                    result.map((entry, index) => {
                                                        return <Cell fill={result[index].color} key={index} />;
                                                    })
                                                }
                                            </Bar>
                                        )}
                                        {type === 'web' && (
                                            <Bar type="monotone" dataKey='pro_revenue' stackId='a'>
                                                {
                                                    result.map((entry, index) => {
                                                        return <Cell fill={lightenDarkenColor(result[index].color, 20)} key={index} />;
                                                    })
                                                }
                                            </Bar>
                                        )}
                                        {type === 'flapp' && (
                                            <Bar type="monotone" dataKey={'iap_revenue_ios'} stackId='a'>
                                                {
                                                    result.map((entry, index) => {
                                                        return <Cell fill={result[index].color} key={index} />;
                                                    })
                                                }
                                            </Bar>
                                        )}
                                        {type === 'flapp' && (
                                            <Bar type="monotone" dataKey={'iap_revenue_android'} stackId='a'>
                                                {
                                                    result.map((entry, index) => {
                                                        return <Cell fill={lightenDarkenColor(result[index].color, 20)} key={index} />;
                                                    })
                                                }
                                            </Bar>
                                        )}
                                        {type === 'flapp' && (
                                            <Bar type="monotone" dataKey={'ads_revenue_ios'} stackId='a'>
                                                {
                                                    result.map((entry, index) => {
                                                        return <Cell fill={lightenDarkenColor(result[index].color, 40)} key={index} />;
                                                    })
                                                }
                                            </Bar>
                                        )}
                                        {type === 'flapp' && (
                                            <Bar type="monotone" dataKey={'ads_revenue_android'} stackId='a'>
                                                {
                                                    result.map((entry, index) => {
                                                        return <Cell fill={lightenDarkenColor(result[index].color, 60)} key={index} />;
                                                    })
                                                }
                                            </Bar>
                                        )}
                                        <XAxis dataKey='name' padding={{ left: 10 }} />
                                        <YAxis />
                                        <Tooltip content={<CustomBarTooltip />} cursor={{fill: 'rgba(0, 0, 0, 0)'}} />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    )}
                </Tab>
                <Tab eventKey='Ads Revenue' title={'Ads Revenue: ' + getTotalRevenue('ads')}>
                    {selectedCats && (
                        <div className='chart-container'>
                            <div>
                                <ResponsiveContainer height={400} width='100%'>
                                    <BarChart data={result.filter(cat => selectedCats.indexOf(cat.name) > -1)}>
                                        {type === 'web' && (
                                            <Bar type="monotone" dataKey='ads_revenue' stackId='a'>
                                                {
                                                    result.map((entry, index) => {
                                                        return <Cell fill={result[index].color} key={index} />;
                                                    })
                                                }
                                            </Bar>
                                        )}
                                        {type === 'flapp' && (
                                            <Bar type="monotone" dataKey={'ads_revenue_ios'} stackId='a'>
                                                {
                                                    result.map((entry, index) => {
                                                        return <Cell fill={lightenDarkenColor(result[index].color, 40)} key={index} />;
                                                    })
                                                }
                                            </Bar>
                                        )}
                                        {type === 'flapp' && (
                                            <Bar type="monotone" dataKey={'ads_revenue_android'} stackId='a'>
                                                {
                                                    result.map((entry, index) => {
                                                        return <Cell fill={lightenDarkenColor(result[index].color, 60)} key={index} />;
                                                    })
                                                }
                                            </Bar>
                                        )}
                                        <XAxis dataKey='name' padding={{ left: 10 }} />
                                        <YAxis />
                                        <Tooltip content={<CustomBarTooltip />} cursor={{fill: 'rgba(0, 0, 0, 0)'}} />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    )}
                </Tab>
                <Tab eventKey='Pro Revenue' title={'Pro Revenue: ' + getTotalRevenue('pro')}>
                    {selectedCats && (
                        <div className='chart-container'>
                            <div>
                                <ResponsiveContainer height={400} width='100%'>
                                    <ComposedChart data={result.filter(cat => selectedCats.indexOf(cat.name) > -1)}>
                                        {type === 'web' && (
                                            <Bar type="monotone" dataKey='pro_revenue' yAxisId='left'>
                                                {
                                                    result.map((entry, index) => {
                                                        return <Cell fill={result[index].color} key={index} />;
                                                    })
                                                }
                                            </Bar>
                                        )}
                                        {type === 'web' && (
                                            <Line type="monotone" dataKey='pro_total_buyers' stroke='#000' yAxisId='right' />
                                        )}
                                        {type === 'flapp' && (
                                            <Bar type="monotone" dataKey={'iap_revenue_ios'} stackId='a' yAxisId='left'>
                                                {
                                                    result.map((entry, index) => {
                                                        return <Cell fill={result[index].color} key={index} />;
                                                    })
                                                }
                                            </Bar>
                                        )}
                                        {type === 'flapp' && (
                                            <Bar type="monotone" dataKey={'iap_revenue_android'} stackId='a' yAxisId='left'>
                                                {
                                                    result.map((entry, index) => {
                                                        return <Cell fill={lightenDarkenColor(result[index].color, 20)} key={index} />;
                                                    })
                                                }
                                            </Bar>
                                        )}
                                        <XAxis dataKey='name' padding={{ left: 10 }} />
                                        <YAxis yAxisId='left' />
                                        <YAxis yAxisId='right' orientation='right' />
                                        <Tooltip content={<CustomBarTooltip />} cursor={{fill: 'rgba(0, 0, 0, 0)'}} />
                                    </ComposedChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    )}
                </Tab>
            </Tabs>

            {selectedCats && (
                <div className='filter-by-category'>
                    <div>
                        <Checkbox
                            checked={selectAllOptionEnabled}
                            onChange={() => toggleSelectedCats()}
                        />
                        <span onClick={() => toggleSelectedCats()}>
                            Select all
                        </span>
                    </div>
                    {result.map((cat, index) => (
                        <div key={index}>
                            <Checkbox
                                checked={selectedCats.indexOf(cat.name) > -1}
                                onChange={() => toggleSelectedCats(cat)}
                                style ={{color: cat.color}}
                            />
                            <span onClick={() => toggleSelectedCats(cat)} style={{color: cat.color}}>
                                {cat.name}
                            </span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

function mapStateToProps(state, props) {
    return {
        startDate: state.filterReducer.startDate,
        endDate: state.filterReducer.endDate,
    }
}

export default connect(mapStateToProps, { })(RevenueChart);