import React from 'react'
import { Modal, Button } from 'react-bootstrap';

const ModalAlert = (props) =>{
    const {show,onHide} = props
    return(
        <Modal
          show={show}
          onHide={onHide}
          backdrop='static'
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Thông báo</Modal.Title>
          </Modal.Header>
          <Modal.Body>Sửa đổi thành công!</Modal.Body>
          <Modal.Footer>
            <Button variant='success' onClick={onHide}>
              Xác nhận
            </Button>
          </Modal.Footer>
        </Modal>
    )
}
export default ModalAlert;