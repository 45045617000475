import { useState, useEffect } from 'react';
import axios from 'axios';
import { Tooltip, XAxis, YAxis, Line, Bar, ResponsiveContainer, ComposedChart } from 'recharts';
import Constants from '../../../utils/constants';
import { getWeekFromDate } from '../../../utils/time';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { formatDate } from '../../../utils/utils';
import { Form } from 'react-bootstrap';
import { faApple, faAndroid } from '@fortawesome/free-brands-svg-icons';

const Reviews = ({startDate, endDate, testID}) => {
    const [reviews, setReviews] = useState([]);
    const [reviewsByWeek, setReviewsByWeek] = useState([]);
    const [avgRatings, setAvgRatings] = useState(0);
    const [selectedStars, setSelectedStars] = useState([1, 2, 3, 4, 5]);
    const [selectedPlatforms, setSelectedPlatforms] = useState(['ios', 'android']);

    useEffect(() => fetchReviews(), []);

    const fetchReviews = async() => {
        const res = await axios.get(Constants.API_ROOT_URL + '/reviews-by-test?testID=' + testID + '&startDate=' + startDate + '&endDate=' + endDate);
        const reviews = res.data;
        let reviewsByWeek = [];
        let sumRatings = 0;
        for (let i = 0; i < reviews.length; i++) {
            const lastUpdate = new Date(reviews[i].lastUpdate);
            const ratingsReviewsIndex = reviewsByWeek.findIndex(week => getWeekFromDate(lastUpdate.getDate()) === week.week && lastUpdate.getMonth() + 1 === week.month);
            if (ratingsReviewsIndex === -1) {
                reviewsByWeek.push({
                    name: 'T' + (lastUpdate.getMonth() + 1) + ' W' + getWeekFromDate(lastUpdate.getDate()),
                    week: getWeekFromDate(lastUpdate.getDate()),
                    month: lastUpdate.getMonth() + 1,
                    scores: [reviews[i].rating],
                });
            } else {
                reviewsByWeek[ratingsReviewsIndex].scores.push(reviews[i].rating);
            }
            sumRatings += reviews[i].rating;
        }
        reviewsByWeek = reviewsByWeek.map(week => {
            const total = week.scores.reduce((total, score) => {
                return total + score;
            }, 0);
            return {
                ...week,
                nbRatings: week.scores.length,
                avgScore: total / week.scores.length
            };
        });
        reviewsByWeek.sort((a, b) => {
            if (a.month < b.month) {
                return -1;
            } else if (a.month > b.month) {
                return 1;
            }

            if (a.week < b.week) {
                return -1;
            }
            return 1;
        });
        setReviewsByWeek(reviewsByWeek);
        setReviews(reviews);
        setAvgRatings(sumRatings / reviews.length);
    }

    const CustomBarTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className='custom-tooltip'>
                    <div className='custom-tooltip-label'>{label}</div>
                    <div className='custom-tooltip-value'>
                        Điểm review trung bình: {payload[1]['payload']['avgScore'].toFixed(1) + '/5'}
                    </div>
                    <div className='custom-tooltip-value'>
                        Số lượt review: {payload[0]['payload']['nbRatings']}
                    </div>
                </div>
            );
        }

        return null;
    }

    const toggleSelectedStars = star => {
        if (selectedStars.indexOf(star) > -1) {
            setSelectedStars(selectedStars.filter(s => s !== star));
        } else {
            setSelectedStars(selectedStars.concat(star));
        }
    }

    const toggleSelectedPlatform = platform => {
        if (selectedPlatforms.indexOf(platform) > -1) {
            setSelectedPlatforms(selectedPlatforms.filter(p => p !== platform));
        } else {
            setSelectedPlatforms(selectedPlatforms.concat(platform));
        }
    }

    return (
        <div>
            <ResponsiveContainer height={400} width='100%' className='chart'>
                <ComposedChart data={reviewsByWeek}>
                    <Bar type='monotone' dataKey='nbRatings' fill='#0c6efd' yAxisId='left' />
                    <Line type='monotone' dataKey='avgScore' stroke='#000' yAxisId='right' />
                    <XAxis dataKey='name' padding={{ left: 10 }} />
                    <YAxis yAxisId='left' />
                    <YAxis yAxisId='right' orientation='right' domain={[0, 5]} />
                    <Tooltip content={<CustomBarTooltip />} cursor={{fill: 'rgba(0, 0, 0, 0)'}} />
                </ComposedChart>
            </ResponsiveContainer>
            <div className='filter-and-summary'>
                <div className='filter'>
                    <div className='filter-by-platforms'>
                        <Form.Check type='checkbox' id='platform-ios'>
                            <Form.Check.Input type='checkbox' checked={selectedPlatforms.indexOf('ios') > -1} onChange={() => toggleSelectedPlatform('ios')} />
                            <Form.Check.Label><FontAwesomeIcon icon={faApple} /> IOS</Form.Check.Label>
                        </Form.Check>
                        <Form.Check type='checkbox' id='platform-android'>
                            <Form.Check.Input type='checkbox' checked={selectedPlatforms.indexOf('android') > -1} onChange={() => toggleSelectedPlatform('android')} />
                            <Form.Check.Label><FontAwesomeIcon icon={faAndroid} /> ANDROID</Form.Check.Label>
                        </Form.Check>
                    </div>
                    <div className='filter-by-rating'>
                        {[...Array(5).keys()].map((star, index) => (
                            <Form.Check type='checkbox' id={'star-' + index} key={index}>
                                <Form.Check.Input type='checkbox' checked={selectedStars.indexOf(star + 1) > -1}
                                    onChange={() => toggleSelectedStars(star + 1)} />
                                <Form.Check.Label>
                                    {[...Array(star + 1).keys()].map((starItem, sIndex) => (
                                        <FontAwesomeIcon icon={faStar} color='#ffb400' key={index + '-' + sIndex} />
                                    ))}
                                </Form.Check.Label>
                            </Form.Check>
                        ))}
                    </div>
                </div>
                <div className='summary'>
                    <div>Total: {reviews.length} reviews</div>
                    <div>Average: {avgRatings.toFixed(1)}</div>
                </div>
            </div>
            <div className='reviews'>
                {reviews
                    .filter(review => selectedStars.indexOf(review.rating) > -1 && selectedPlatforms.indexOf(review.platform) > -1)
                    .map((review, index) => (
                    <div className='review' key={index}>
                        <div className='review-header'>
                            <div>
                                <div className='review-title'>{review.title}</div>
                                <div className='review-rating'>
                                    <span>{review.rating}</span>
                                    <FontAwesomeIcon icon={faStar} color={review.rating >=3 ? '#ffb400' : '#dc3545'} />
                                </div>
                            </div>
                            <div>
                                <div className='review-author'>{review.author}</div>
                                <div className='review-date'>{formatDate(new Date(review.lastUpdate), 'dd/mm/yyyy')}</div>
                            </div>
                        </div>
                        <div className='review-content'>{review.content}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Reviews;