
import { UserAction, UserActionType } from '../actions/user';

export interface UserState {
    permissions: any[]
}

const initState: UserState = {
    permissions: []
}

function UserState(
    state: UserState = initState,
    action: UserAction
): UserState {
    switch (action.type) {
        case UserActionType.SET_USER_PERMISSIONS:
            return {
                ...state,
                permissions : action.permissions,
            }
        default:
            return state;
    }
}

export default UserState;
