import { connect } from 'react-redux';
import Constants from '../../utils/constants';
import './styles.scss';
import {updateActiveSegment} from '../Filter/filter.actions';

function SideBar({updateActiveSegment, filter}) {
    const getSegmentClass = segment => {
        if (segment.value === 'ratings_reviews' && filter.flapps.length === 0 && filter.rnapps.length === 0) {
            return 'disabled';
        } else if (filter.activeSegment === segment.value) {
            return 'active';
        }
        return '';
    }

    const updateSegmentResult = segment => {
        if (getSegmentClass(segment) != 'disabled') {
            updateActiveSegment(segment.value);
        }
    }

    return (
        <div className='sidebar'>
            {Constants.DASHBOARD_SEGMENT_LIST.map((segment, index) => (
                <div key={index} onClick={() => updateSegmentResult(segment)}
                    className={getSegmentClass(segment)}>
                    {segment.label}
                </div>
            ))}
        </div>
    );
}
function mapStateToProps(state, props) {
    return {
        filter: state.filterReducer,
    };
}
export default connect(mapStateToProps, {updateActiveSegment})(SideBar);