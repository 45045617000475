import React from "react";
import DisplayQuestion from './DisplayQuestion';
const DisplayQuestionList = ({ questionList, handleModalOpen }) => {
  return (
    <div>
      {questionList.map((question) => (
        <DisplayQuestion
          question={question}
          handleModalOpen={handleModalOpen}
        />
      ))}
    </div>
  );
};
export default DisplayQuestionList;