import {useEffect}from 'react';
import { useDispatch } from 'react-redux';
import Constants from '../../utils/constants';
import axios from 'axios';
import {updateErrorNumber} from '../../redux/actions/webStatus';
import { Container, Row, Col } from 'react-bootstrap';
import './styles.scss';
import Filter from '../../component/Filter';
import Result from '../../component/Result';
import SideBar from '../../component/SideBar/SideBar';

const Dashboard = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        getDataWebStatus(Constants.URL_WEB_STATUS);
    }, []);

    const getDataWebStatus = async (url) => {
        await axios.post(url).then(function(response) {
            const data = response?.data;
            let errorNumber = 0;
            for(let k = 0; k < data?.length; k ++){
                let dataItem = data[k];
                for(let i = 0; i < dataItem.page?.length; i++){
                    let item = dataItem.page[i];
                    for (let j = 0; j < item.list?.length; j++){
                        let itemValue = item.list[j];
                        let status = itemValue.status;
                        if((status < 200) || (status > 300)) {
                            errorNumber = errorNumber + 1;
                        }
                    }
                }
            }
            dispatch(updateErrorNumber(errorNumber));
        });
    };

    return (
        <Container fluid className='dashboard'>
            <Row className='filter-container'>
                <Col>
                    <Filter />
                </Col>
            </Row>
            <div>
                <SideBar />
                <Result />
            </div>
        </Container>
    );
};
export default Dashboard;
