import './App.scss';
import Dashboard from './pages/dashboard';
import SignIn from './component/SignIn';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AuthenticatedRoute from './auth/AuthenticatedRoute';
import WebStatus from '../src/component/webStatus/index';

import { ContentCheckerScreen } from './pages/contentChecker';
import QuestionQuality from './component/question-quality';
import ABTests from './pages/abtests';

function App() {
    return (
        <Router>
            <Switch>
                <Route exact path='/signin' component={SignIn} />
                <AuthenticatedRoute path='/webStatus' exact={true} component={WebStatus} />
                <AuthenticatedRoute path='/' exact={true} component={Dashboard} />
                <AuthenticatedRoute path='/question-quality' exact={true} component={QuestionQuality} />
                <AuthenticatedRoute path='/abtests' exact={true} component={ABTests} />
                <AuthenticatedRoute
                    path='/ContentChecker'
                    exact={true}
                    component={ContentCheckerScreen}
                />
            </Switch>
        </Router>
    );
}

export default App;
