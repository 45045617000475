import { IMetric } from "../../models/metric";
import { ICategory } from "../../models/category";
import {
  SearchFilterAction,
  SearchFilterActionType,
} from "../actions/searchFilter";
import Constants from "../../utils/constants";
import { ICategoryState, CategoryState } from "../../models/searchFilter";

export interface SearchFilterState {
  categoryState: ICategoryState[];
  metric: IMetric[];
  startDate: string;
  endDate: string;
  error: any;
  showChart: boolean;
  dataChart: null,
  countSavedFilter: number;
  platformChart: number;
}
const initState: SearchFilterState = {
  categoryState: [
    new CategoryState(),
    new CategoryState(),
    new CategoryState(),
  ],
  metric: [],
  startDate: "",
  endDate: "",
  error: null,
  showChart: false,
  dataChart: null,
  countSavedFilter: 0,
  platformChart: 0,
};
function searchFilterState(
  state: SearchFilterState = initState,
  action: SearchFilterAction
): SearchFilterState {
  switch (action.type) {
    case SearchFilterActionType.UPDATE_CATEGORY:
      let indexPlatformUpdateCategory = (action.platform ?? 0) - 1;
      let updateCategoryState = state.categoryState.map(
        (categoryState, index) => {
          if (index === indexPlatformUpdateCategory) {
            if (action.category) {
              let copyCategory = [...categoryState.listCategory];
              let listCategoryName = copyCategory.map((el) => el.name);
              let indexActionCategoryName = listCategoryName.indexOf(
                action.category.name
              );
              if (indexActionCategoryName === -1) {
                copyCategory.push({ ...action.category });
              } else {
                copyCategory.splice(indexActionCategoryName, 1);
              }
              return {
                ...categoryState,
                listCategory: [...copyCategory],
                analytic: null,
                loading: false,
                showTable: false,
              };
            }
          }
          return { ...categoryState, showTable: false, analytic: null };
        }
      );

      let deleteAllMetric = !(
        updateCategoryState.filter((el) => el.listCategory.length > 0).length >
        0
      );
      return {
        ...state,
        categoryState: [...updateCategoryState],
        metric: deleteAllMetric ? new Array<IMetric>() : [...state.metric],
      };
    case SearchFilterActionType.UPDATE_METRIC:
      let copyMetric = [...state.metric];
      let updateCategoryStateMetric = state.categoryState.map(
        (categoryState) => {
          return { ...categoryState, showTable: false };
        }
      );
      let listMetricName = copyMetric.map((el) => el.name);
      if (action.metric) {
        let indexMetricName = listMetricName.indexOf(action.metric.name);
        if (indexMetricName === -1) {
          if (action.metric.typeTable !== Constants.TYPE_TABLE_EVENTS) {
            copyMetric.unshift(action.metric)
          } else {
            copyMetric.push(action.metric);
          }
        } else {
          copyMetric.splice(indexMetricName, 1);
        }
      }

      return {
        ...state,
        categoryState: [...updateCategoryStateMetric],
        metric: [...copyMetric],
      };
    case SearchFilterActionType.SEARCH_FILTER:
      let categoryStateSearchFilter = state.categoryState.map(
        (categoryState) => {
          return {
            ...categoryState,
            loading: false,
            showTable: false,
            dataTable: null,
          };
        }
      );
      return {
        ...state,
        categoryState: [...categoryStateSearchFilter],
      };
    case SearchFilterActionType.SEARCH_FILTER_FAILED:
      return {
        ...state,
        error: action.error ?? "",
      };
    case SearchFilterActionType.UPDATE_TIME:
      if (action.startDate?.length) {
        state.startDate = action.startDate;
      }
      if (action.endDate?.length) {
        state.endDate = action.endDate;
      }
      return {
        ...state,
      };
    case SearchFilterActionType.FILTER_ALL_CATEGORY:
      let indexState = (action.platform ?? 0) - 1;
      // let newCategoryWeb: ICategoryState =
      let newStateFilterAll = state.categoryState.map((el, index) => {
        if (index === indexState) {
          return {
            listCategory:
              el.listCategory.length === action.listCategory?.length
                ? new Array<ICategory>()
                : action.listCategory?.map((el) => el) ?? [],
            loading: false,
            showTable: false,
            dataTable: null,
            metrics: el.metrics,
          };
        }
        return el;
      });
      let removeAllMetric = !(
        newStateFilterAll.filter((el) => el.listCategory.length > 0).length >
        0
      );
      return {
        ...state,
        categoryState: [...newStateFilterAll],
        metric: removeAllMetric ? new Array<IMetric>() : [...state.metric],
      };
    case SearchFilterActionType.SEARCH_CATEGORY:
      let indexPlatform = (action.platform ?? 0) - 1;
      let categoryStateSearch = state.categoryState.map(
        (categoryState, index) => {
          if (index === indexPlatform) {
            return {
              ...categoryState,
              loading: true,
            };
          }
          return categoryState;
        }
      );
      return {
        ...state,
        categoryState: [...categoryStateSearch],
      };
    case SearchFilterActionType.SEARCH_CATEGORY_SUCCESS:
      let indexUpdateAnalytic = (action.platform ?? 0) - 1;
      let updateAnalytic = state.categoryState.map((categoryState, index) => {
        if (index === indexUpdateAnalytic) {
          return {
            ...categoryState,
            loading: false,
            dataTable: action.dataTable,
            metrics: state.metric.filter(el => el.type.indexOf(action.platform ?? 0) !== -1),
            showTable: true,
          };
        }
        return categoryState;
      });
      return {
        ...state,
        categoryState: [...updateAnalytic],
        // loadingAppFlutter: true,
      };
    case SearchFilterActionType.SEARCH_CATEGORY_FAILED:
      let indexUpdateAnalyticFailed = (action.platform ?? 0) - 1;
      let updateAnalyticFailed = state.categoryState.map(
        (categoryState, index) => {
          if (index === indexUpdateAnalyticFailed) {
            return {
              ...categoryState,
              loading: false,
              showTable: false,
            };
          }
          return categoryState;
        }
      );
      return {
        ...state,
        categoryState: [...updateAnalyticFailed],
        error: action.error ?? null,
        // loadingAppFlutter: true,
      };
    case SearchFilterActionType.FILTER_ALL_METRIC:
      let resultMetric: IMetric[] = [];
      if (action.listMetric !== undefined) {
        let typeTable = action.listMetric[0].typeTable;
        let filterMetric = state.metric.filter(
          (el) => el.typeTable !== typeTable
        );
        if (
          state.metric.length ===
          filterMetric.length + action.listMetric.length
        ) {
          resultMetric = [...filterMetric];
        } else {
          if (action.listMetric[0].typeTable === Constants.TYPE_TABLE_EVENTS) {
            resultMetric = [...filterMetric, ...action.listMetric];
          } else {
            resultMetric = [...action.listMetric, ...filterMetric];

          }
        }
      }
      return {
        ...state,
        metric: [...resultMetric],
      };
    case SearchFilterActionType.SET_ERROR:
      return {
        ...state,
        error: null,
      };
    case SearchFilterActionType.LOAD_DATA_FROM_LOCAL_STORAGE:
      let localCategory = state.categoryState.map((categoryState, index) => {
        if (action.listCategoryState) {
          return {
            ...categoryState,
            listCategory: action.listCategoryState[index],
            analytic: null,
            loading: false,
            showTable: false,
          };
        }
        return {
          ...categoryState,
          analytic: null,
          loading: false,
          showTable: false,
        };
      });
      return {
        ...state,
        categoryState: [...localCategory],
        metric: [...(action.listMetric ?? [])],
      };
    case SearchFilterActionType.UPDATE_COUNT_SAVED_FILTER:
      return {
        ...state,
        countSavedFilter: action.countSavedFilter ?? 0,
      };
    default:
      return state;
  }
}

export default searchFilterState;
