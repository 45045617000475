import { combineReducers } from "redux";
import { AppState } from "../app_state";
import searchFilterState from "./searchFilter";
import searchChartState from "./searchChart";
import webStatusState from "./webStatus";
import filterReducer from '../../component/Filter/filter.reducer';
import resultReducer from '../../component/Result/result.reducer';
import userState from './user';


const reducers = combineReducers({
    searchFilterState,
    searchChartState,
    webStatusState,
    filterReducer,
    resultReducer,
    userState,
});

export default reducers;
