import React from 'react';
import './index.scss';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const DisplayQuestion = ({ question,handleModalOpen }) => {
  return (
    <div className='card'>
      <div className='card-body'>
        <p className='card-text'>{question.question}</p>
        <h7 className='card-title'>{question.id}</h7>
      </div>
      <ul className='list-group list-group-flush'>
        {question.answers.map((A) => {
          return (
            <li className='list-group-item'>
              <div className='selection_collapse'>
                <CheckIcon className='icon_green' />
                {A}
              </div>
            </li>
          );
        })}
        {question.choices.map((C) => {
          return (
            <li className='list-group-item'>
              <div className='selection_collapse'>
                <CloseIcon className='icon_red' />
                {C}
              </div>
            </li>
          );
        })}
      </ul>
      <div className='card-body'>
        <button
          className='btn btn-primary'
          onClick={() => handleModalOpen(question)}
        >
          Chỉnh sửa câu hỏi
        </button>
      </div>
    </div>
  );
};
export default DisplayQuestion;
