const TestInfo = ({test}) => {
    return (
        <div className='test-info'>
            <div className='panel'>
                <h3>Số câu hỏi trong DB</h3>
                <div>{test.nbQuestions}</div>
            </div>
            <div className='panel'>
                <h3>Số câu hỏi trong đề thi</h3>
                <div>{test.realTestNbQuestions}</div>
            </div>
            <div className='panel'>
                <h3>Thời gian thi</h3>
                <div>{test.duration}</div>
            </div>
            <div className='panel'>
                <h3>Tỷ lệ đủ để pass</h3>
                <div>{test.passPercent}%</div>
            </div>
        </div>
    )
}

export default TestInfo;