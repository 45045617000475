import { connect } from 'react-redux';
import { Container, Navbar, Nav } from 'react-bootstrap';
import './styles.scss';
import { useLocation, Link } from 'react-router-dom';

function NavBar({ data }) {
    const location = useLocation();

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('email');
    }

    return (
        <Navbar position='static' bg='dark'>
            <Container fluid>
                <div>
                    <Navbar.Brand href='/'>
                        Data Analytic
                    </Navbar.Brand>
                    <Nav>
                        <Nav.Link as={Link} to='/' className={location.pathname === '/' && 'active'}>
                            Dashboard
                        </Nav.Link>
                        <Nav.Link as={Link} to='/question-quality' className={location.pathname === '/question-quality' && 'active'}>
                            Content Quality
                        </Nav.Link>
                        <Nav.Link as={Link} to='/abtests' className={location.pathname === '/abtests' && 'active'}>
                            A/B Testing
                        </Nav.Link>
                    </Nav>
                </div>
                <Nav className='user'>
                    <div className='username'>{localStorage['email']}</div>
                    <Nav.Link href='/signin' className='is-active' onClick={() => logout()}>
                        Log out
                    </Nav.Link>
                </Nav>
            </Container>
        </Navbar>
    );
}
function mapStateToProps(state, props) {
    return {
        data: state,
    };
}
export default connect(mapStateToProps)(NavBar);