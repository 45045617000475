import './index.scss';
import React from 'react';
import Select from 'react-select';
import axios from 'axios';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';
import ModalAlert from './ModalAlert';
import Constants from '../../utils/constants';
import Collapse from './Collapse';
var questionNoAnswer = [];
var questionAnswerLength = [];
var questionManyAnswerCorrect = [];
const PER_PAGE = 10;
export default class ContentChecker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: [],
      selectedOption: null,
      questions: [],
      numberOfQuestion: 0,
      currentPart: 0,
      questionNoAnswer: [],
      questionAnswerLength: [],
      questionManyAnswerCorrect: [],
      currentPageMany: 0,
      currentPageNo: 0,
      currentPageLength: 0,
      modalOpen: false,
      modalQuestion: [],
      alertShow: false,
      modalSelection: [], //selection=answers + choices
    };
  }
  resetState = () => {
    this.setState({
      currentPart: 0,
      questionNoAnswer: [],
      questionAnswerLength: [],
      questionManyAnswerCorrect: [],
      questions: [],
      numberOfQuestion: 0,
      currentPageMany: 0,
      currentPageNo: 0,
      currentPageLength: 0,
    });
  };
  handleModalOpen = (question) => {
    this.setState({ modalOpen: true });
    this.setState({ modalQuestion: question });
    var selection = [];
    for (let value of question.answers) {
      selection.push({ selection: value, id: true });
    }
    for (let value of question.choices) {
      selection.push({ selection: value, id: false });
    }
    this.setState({ modalSelection: selection });
  };
  handleModalClose = () => {
    this.setState({ modalOpen: false });
  };
  handleAlertOpen = () => {
    this.setState({ alertShow: true });
  };
  handleAlertClose = () => {
    this.setState({ alertShow: false });
  };
  handlePageMany = ({ selected: selectedPage }) => {
    this.setState({ currentPageMany: selectedPage });
  };
  handlePageNo = ({ selected: selectedPage }) => {
    this.setState({ currentPageNo: selectedPage });
  };
  handlePageLength = ({ selected: selectedPage }) => {
    this.setState({ currentPageLength: selectedPage });
  };
  componentDidMount() {
    this.getAppName();
  }
  getAppName = () => {
    axios.get(Constants.API + '/api/get_all_app_info').then((res) => {
      this.setState({ values: res.data });
    });
  };
  handleChangeSelectedApp = (selectedApp) => {
    questionNoAnswer = [];
    questionAnswerLength = [];
    questionManyAnswerCorrect = [];
    this.resetState();
    this.setState({ selectedOption: selectedApp });
    this.getNumberOfQuestion(selectedApp.value);
  };
  getNumberOfQuestion = (appID) => {
    axios
      .get(Constants.API + '/api/get_question_app_by_id/' + appID)
      .then((res) => {
        this.setState({ numberOfQuestion: res.data });
      })
      .then(() => this.getAllQuestion(appID));
  };
  getAllQuestion = async (appID) => {
    let count = 0;
    while (count < this.state.numberOfQuestion) {
      const res = await axios.get(
        Constants.API +
          '/api/get_all_question_by_app_id/' +
          appID +
          '/&offset/' +
          count +
          '/&limit/100'
      );
      this.setState({ questions: res.data });
      this.checkAllQuestionApp(this.state.questions);
      count += 100;
      this.setState({ currentPart: count / 100 });
    }
  };
  checkAllQuestionApp = (questions) => {
    questions.map((question) => {
      if (question.type === 'No Answer') questionNoAnswer.push(question.info);
      else if (question.type === 'Length')
        questionAnswerLength.push(question.info);
      else if (question.type === 'Many')
        questionManyAnswerCorrect.push(question.info);
      return null;
    });
    this.setState({
      questionNoAnswer,
      questionAnswerLength,
      questionManyAnswerCorrect,
    });
  };
  compareAppName = (a, b) => {
    const appNameA = a.label;
    const appNameB = b.label;
    let comparison = 0;
    if (appNameA > appNameB) {
      comparison = 1;
    } else if (appNameA < appNameB) {
      comparison = -1;
    }
    return comparison;
  };
  setOption = (array) => {
    let option = [];
    if (array.length > 0) {
      array.forEach((app) => {
        let appData = {};
        appData.value = app.id;
        appData.label = app.appName;
        option.push(appData);
      });
    }
    option.sort(this.compareAppName);
    return option;
  };
  render() {
    let option = this.setOption(this.state.values);
    var offsetMany = this.state.currentPageMany * PER_PAGE;
    const currentPageManyData = this.state.questionManyAnswerCorrect.slice(
      offsetMany,
      offsetMany + PER_PAGE
    );
    var offsetNo = this.state.currentPageNo * PER_PAGE;
    const currentPageNoData = this.state.questionNoAnswer.slice(
      offsetNo,
      offsetNo + PER_PAGE
    );
    var offsetLength = this.state.currentPageLength * PER_PAGE;
    const currentPageLengthData = this.state.questionAnswerLength.slice(
      offsetLength,
      offsetLength + PER_PAGE
    );
    return (
      <div className='content_checker'>
        <Select
          options={option}
          onChange={this.handleChangeSelectedApp}
          className='mySelect'
        />
        <h7 className='title'>Warning: Do not change exam while loading!!!</h7>
        <div className='loading'>{`Loading: ${
          this.state.currentPart
        } / ${Math.ceil(this.state.numberOfQuestion / 100)} parts`}</div>

        <div>
          <Collapse
            triggerTitle={'Câu hỏi có nghi vấn về độ dài câu trả lời:'}
            problemList={this.state.questionAnswerLength}
            currentPageData={currentPageLengthData}
            PER_PAGE={PER_PAGE}
            handleModalOpen={this.handleModalOpen}
            handlePage={this.handlePageLength}
          />
          <Collapse
            triggerTitle={
              'Câu hỏi có nhiều đáp án đúng hoặc nhiều hơn 5 lựa chọn:'
            }
            problemList={this.state.questionManyAnswerCorrect}
            currentPageData={currentPageManyData}
            PER_PAGE={PER_PAGE}
            handleModalOpen={this.handleModalOpen}
            handlePage={this.handlePageMany}
          />
          <Collapse
            triggerTitle={'Câu hỏi không có đáp án đúng/sai:'}
            problemList={this.state.questionNoAnswer}
            currentPageData={currentPageNoData}
            PER_PAGE={PER_PAGE}
            handleModalOpen={this.handleModalOpen}
            handlePage={this.handlePageNo}
          />
        </div>
        <Modal
          show={this.state.modalOpen}
          onHide={this.handleModalClose}
          backdrop='static'
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>ID:{this.state.modalQuestion.id}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='card'>
              <div className='card-body'>
                <p
                  className='card-text'
                  contentEditable={true}
                  suppressContentEditableWarning={true}
                >
                  {this.state.modalQuestion.question}
                </p>
              </div>
              <ul className='list-group list-group-flush'>
                {this.state.modalSelection.map((S) => {
                  return (
                    <li className='list-group-item'>
                      <div className='selection'>
                        <Button
                          variant='light'
                          onClick={() => {
                            let updateState = this.state.modalSelection;
                            for (let value of updateState) {
                              if (value.selection === S.selection) {
                                value.id = !S.id;
                              }
                            }
                            this.setState({ modalSelection: updateState });
                          }}
                        >
                          {S.id ? (
                            <CheckIcon className='icon_green' />
                          ) : (
                            <CloseIcon className='icon_red' />
                          )}
                        </Button>
                        <p
                          className='card-text'
                          contentEditable={true}
                          suppressContentEditableWarning={true}
                        >
                          {S.selection}
                        </p>
                        <Button
                          variant='light'
                          onClick={() => {
                            let updateState = this.state.modalSelection;
                            for (var i = 0; i < updateState.length; i++) {
                              if (updateState[i].selection === S.selection) {
                                updateState.splice(i, 1);
                                break;
                              }
                            }
                            this.setState({ modalSelection: updateState });
                          }}
                        >
                          <DeleteIcon />
                        </Button>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={this.handleModalClose}>
              Đóng
            </Button>
            <Button
              variant='primary'
              onClick={() => {
                this.handleModalClose();
                this.handleAlertOpen();
              }}
            >
              Xác nhận
            </Button>
          </Modal.Footer>
        </Modal>
        <ModalAlert
          show={this.state.alertShow}
          onHide={this.handleAlertClose}
        />
      </div>
    );
  }
}