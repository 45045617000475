import { IMetric } from "../../models/metric";

export interface SearchChartAction {
    type: SearchChartActionType;
    listMetric?: IMetric[];
    dataChart?: any;
    platform?: number
    appIds?: number[];
    nameChart?: string[]
}
export enum SearchChartActionType {
    SEARCH_CHART = "SEARCH_CHART",
    SEARCH_CHART_SUCCESS = "SEARCH_CHART_SUCCESS",
    SEARCH_CHART_FAILED = "SEARCH_CHART_FAILED",
    REMOVE_ALL = "REMOVE_ALL"
    // SET_EMPTY_TESTS = "SET_EMPTY_TESTS",
}
export const searchChart = (listMetric: IMetric[], appIds: number[], platform: number, nameChart: string[]) => {
    return {
        type: SearchChartActionType.SEARCH_CHART,
        listMetric,
        appIds,
        platform,
        nameChart
    }
}
export const searchChartSuccess = (dataChart: any, platform: number, listMetric: IMetric[]) => {
    return {
        type: SearchChartActionType.SEARCH_CHART_SUCCESS,
        dataChart,
        platform,
        listMetric
    }
}
export const searchChartFailed = () => {
    return {
        type: SearchChartActionType.SEARCH_CHART_FAILED,
    }
}
export const removeAll = () => {
    return {
        type: SearchChartActionType.REMOVE_ALL
    }
}