import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import NavBar from '../component/NavBar/NavBar';
import { useEffect } from 'react';
import axios from 'axios';
import Constants from '../utils/constants';
import { setUserPermissions } from '../redux/actions/user';
import {connect, useDispatch} from 'react-redux';

const AuthenticatedRoute = ({ component: Component, setUserPermissions, ...rest }) => {
    const token = localStorage.getItem('token');
    const email = localStorage.getItem('email');
    const dispatch = useDispatch();

    useEffect(() => {
        fetchUserPermission();
    }, []);

    const fetchUserPermission = async() => {
        const res = await axios.get(Constants.API_ROOT_URL + '/get-user-group-permissions?email=' + email);
        dispatch(setUserPermissions(res.data));
    }

    return (
        <Route
            {...rest}
            render={props =>
                token !== null ? (
                    <div>
                        <NavBar/>
                        <Component {...props} />
                    </div>

                ) : (
                    <Redirect to={{ pathname: '/signin', state: { from: props.location } }} />
                )
            }
        />
    )
}

function mapStateToProps(state, props) {
    return {};
}

export default connect(mapStateToProps, {setUserPermissions})(AuthenticatedRoute);