import { IMetric } from "../../models/metric";
import { ICategory } from "../../models/category";

export interface SearchFilterAction {
  type: SearchFilterActionType;
  metric?: IMetric;
  category?: ICategory;
  listCategory?: ICategory[];
  listCategoryState?: [ICategory[]];
  listMetric?: IMetric[];
  startDate?: string;
  endDate?: string;
  dataTable?: any;
  error?: any;
  filterName?: string;
  platform?: number
  countSavedFilter?: number;
}
export enum SearchFilterActionType {
  UPDATE_CATEGORY = "UPDATE_CATEGORY",
  UPDATE_METRIC = "UPDATE_METRIC",
  SEARCH_FILTER = "SEARCH_FILTER",
  SEARCH_CATEGORY = "SEARCH_CATEGORY",
  SEARCH_CATEGORY_FAILED = "SEARCH_CATEGORY_FAILED",
  SEARCH_CATEGORY_SUCCESS = "SEARCH_CATEGORY_SUCCESS",
  UPDATE_TIME = "UPDATE_TIME",
  FILTER_ALL_CATEGORY = "FILTER_ALL_CATEGORY",
  FILTER_ALL_METRIC = "FILTER_ALL_METRIC",
  SEARCH_FILTER_FAILED = "SEARCH_FILTER_FAILED",
  SET_ERROR = "SET_ERROR",
  SAVE_FILTER = "SAVE_FILTER",
  UPDATE_COUNT_SAVED_FILTER = "UPDATE_COUNT_SAVED_FILTER",
  GET_FILTER = "GET_FILTER",
  LOAD_DATA_FROM_LOCAL_STORAGE = "LOAD_DATA_FROM_LOCAL_STORAGE",

  // SET_EMPTY_TESTS = "SET_EMPTY_TESTS",
}

export const updateCategory = ({ category, platform }: { category: ICategory, platform: number }) => {
  return {
    type: SearchFilterActionType.UPDATE_CATEGORY,
    category: category,
    platform
  };
};
export const updateMetric = ({ metric }: { metric: IMetric }) => {
  return {
    type: SearchFilterActionType.UPDATE_METRIC,
    metric: metric,
  };
};
export const updateTime = ({
  startDate,
  endDate,
}: {
  startDate?: string;
  endDate?: string;
}) => {
  return {
    type: SearchFilterActionType.UPDATE_TIME,
    startDate: startDate ?? "",
    endDate: endDate ?? "",
  };
};
export const searchFilter = (): SearchFilterAction => {
  return {
    type: SearchFilterActionType.SEARCH_FILTER,
  };
};
export const searchCategory = (platform: number): SearchFilterAction => {
  return {
    type: SearchFilterActionType.SEARCH_CATEGORY,
    platform
  };
};
export const searchCategorySuccess = (
  dataTable: any,
  platform: number
): SearchFilterAction => {
  return {
    type: SearchFilterActionType.SEARCH_CATEGORY_SUCCESS,
    dataTable,
    platform
  };
};
export const searchCategoryFailed = (error: any, platform: number): SearchFilterAction => {
  return {
    type: SearchFilterActionType.SEARCH_CATEGORY_FAILED,
    platform,
    error: error,
  };
};

export const filterAllCategory = ({
  filterAllCategory,
  platform
}: {
  filterAllCategory: ICategory[];
  platform: number
}): SearchFilterAction => {
  return {
    type: SearchFilterActionType.FILTER_ALL_CATEGORY,
    listCategory: filterAllCategory,
    platform
  };
};
export const filterAllMetric = ({
  filterAllMetric,
}: {
  filterAllMetric: IMetric[];
}): SearchFilterAction => {
  return {
    type: SearchFilterActionType.FILTER_ALL_METRIC,
    listMetric: filterAllMetric,
  };
};
export const searchFilterFailed = ({
  error,
}: {
  error: any;
}): SearchFilterAction => {
  return {
    type: SearchFilterActionType.SEARCH_FILTER_FAILED,
    error: error,
  };
};
export const setError = () => {
  return {
    type: SearchFilterActionType.SET_ERROR
  }
}
export const saveFilter = ({ value }: { value: string }): SearchFilterAction => {
  return {
    type: SearchFilterActionType.SAVE_FILTER,
    filterName: value
  }
}
export const getFilter = ({ filterName }: { filterName: string }) => {
  return {
    type: SearchFilterActionType.GET_FILTER,
    filterName: filterName
  }
}
export const loadDataFromLocalStorage = ({ listCategoryState, listMetric }: { listCategoryState: [ICategory[]], listMetric: IMetric[] }): SearchFilterAction => {
  return {
    type: SearchFilterActionType.LOAD_DATA_FROM_LOCAL_STORAGE,
    listCategoryState,
    listMetric
  }
}
export const updateCountSavedFilter = (countSavedFilter: number) => {
  return {
    type: SearchFilterActionType.UPDATE_COUNT_SAVED_FILTER,
    countSavedFilter
  }
}