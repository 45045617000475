
export interface WebStatusAction {
    type: WebStatusActionType;
    errorNumber?: number;
}
export enum WebStatusActionType {
    UPDATE_ERROR_NUMBER = "UPDATE_ERROR_NUMBER",
}
export const updateErrorNumber = (errorNumber: number) => {
    return {
        type: WebStatusActionType.UPDATE_ERROR_NUMBER,
        errorNumber
    }
}
