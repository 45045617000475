import { useState, useEffect } from 'react';
import axios from 'axios';
import Constants from '../../../utils/constants';

const MessengerFeedbacks = ({testID}) => {
    const [feedbacks, setFeedbacks] = useState([]);

    useEffect(() => {
        fetchFeedbacks();
    }, []);

    const fetchFeedbacks = async() => {
        const res = await axios.get(Constants.API_ROOT_URL + '/messenger-feedbacks?test=' + testID + '&category=content');
        setFeedbacks(res.data);
    }

    return (
        <div className='fb-messenger-feedbacks'>
            {feedbacks.map((feedback, index) => (
                <div className='feedback' key={index}>
                    <a href={feedback.url} target='_blank'>{feedback.summary}</a>
                </div>
            ))}
        </div>
    )
}

export default MessengerFeedbacks;