
import { WebStatusAction, WebStatusActionType } from "../actions/webStatus";

export interface WebStatusState {
    errorNumber : any
}
const initState: WebStatusState = {
    errorNumber : 0
    
};
function webStatusState(
    state: WebStatusState = initState,
    action: WebStatusAction
): WebStatusState {
    switch (action.type) {
        case WebStatusActionType.UPDATE_ERROR_NUMBER:
            return {
                ...state,
                errorNumber : action.errorNumber,
            }
        default:
            return state;
    }
}

export default webStatusState;
