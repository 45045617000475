import 'date-fns';
import React, {useState} from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { formatDate } from '../../utils/utils';
import Constants from '../../utils/constants';
import { makeStyles } from '@material-ui/core/styles';
import { updateStartDate, updateEndDate } from './filter.actions';
import {connect} from 'react-redux';

const useStyles = makeStyles((theme) => ({
    datePicker: {
        marginRight: 15,
    }
}));

const Pickers = ({updateStartDate, updateEndDate}) => {
    const classes = useStyles();
    const [startDate, setStartDate] = useState(Constants.MAX_TIME);
    const [endDate, setEndDate] = useState(Constants.MAX_TIME);

    const startDatePicker = date => {
        if (date && !isNaN(date.getTime())) {
            let startDate = formatDate(date);
            updateStartDate(startDate);
        }
        setStartDate(date);
    };

    const endDatePicker = date => {
        console.log("date", date, date?.getTime(), date instanceof Date);
        if (date && !isNaN(date.getTime())) {
            let endDate = formatDate(date);
            updateEndDate(endDate);
        }
        setEndDate(endDate);
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                clearable
                fullWidth={true}
                margin="normal"
                label="Ngày bắt đầu"
                format="dd/MM/yyyy"
                value={startDate}
                inputVariant="outlined"
                placeholder="01/12/2021"
                onChange={(date) => startDatePicker(date)}
                invalidDateMessage="Invalid Date"
                maxDate={endDate}
                KeyboardButtonProps={{
                    "aria-label": "change date",
                }}
                className={classes.datePicker}
            />
            <KeyboardDatePicker
                clearable
                fullWidth={true}
                margin="normal"
                label="Ngày kết thúc"
                format="dd/MM/yyyy"
                value={endDate}
                invalidDateMessage="Invalid Date"
                onChange={(date) => endDatePicker(date)}
                maxDate={Constants.MAX_TIME}
                disableFuture
                inputVariant="outlined"
                placeholder="01/12/2021"
                KeyboardButtonProps={{
                    "aria-label": "change date",
                }}
                className={classes.datePicker}
            />
        </MuiPickersUtilsProvider>
    );
}

function mapStateToProps(state, props) {
    return {
        startDate: state.filterReducer.startDate,
        endDate: state.filterReducer.endDate,
    }
}

export default connect(mapStateToProps, {updateStartDate, updateEndDate})(Pickers);
