import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useHistory } from 'react-router-dom';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import axios from 'axios';
import Constants from '../../utils/constants';
const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const SignIn = () => {
    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    let history = useHistory();
    const dispatch = useDispatch();

    const login = async(user) => {
        try {
            const response = await axios.post(Constants.API_ROOT_URL + '/api-token-auth/', {
                username: user.email,
                password: user.password,
            });
            let responseData = response.data;
            if (response.data !== null) {
                if (response.status === 200) {
                    localStorage.setItem('token', responseData['token']);
                    localStorage.setItem('email', user.email);
                }
            }
            return response.data;
        } catch (err) {
            window.alert('Email hoặc mật khẩu không đúng');
        }
    }

    useEffect(() => {
        const userID = localStorage.getItem("userID");
        if (userID !== null) {
            history.push(`/`);
        }
    }, [history]);

    const handleChangeEmail = (event) => {
        setEmail(event.target.value);
    };
    const handleChangePassword = (event) => {
        setPassword(event.target.value);
    };
    const submitSignIn = () => {
        const user = {
            email: email,
            password: password,
        };
        login(user, dispatch).then((res) => {
            if (res) {
                history.push(`/`);
            }
        });

    };

    return (
        <div>

            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Đăng nhập
                    </Typography>
                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={handleChangeEmail}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={handleChangePassword}
                        />

                        <Button
                            //type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={() => {
                                submitSignIn();
                            }}
                        >
                            Đăng nhập
                        </Button>
                    </form>
                </div>
            </Container>


        </div>
    );
};


export default SignIn;
