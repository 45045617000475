import { useState, useEffect } from 'react';
import axios from 'axios';
import './styles.scss';
import Constants from '../../utils/constants';
import { Container, Col, Row, Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import Reviews from './reviews';
import NPSAndReportMitstakes from './nps-and-report-mistakes';
import TestInfo from './test-info';
import MessengerFeedbacks from './messenger-feedbacks';
import EmailFeedbacks from './email-feedbacks';

const QuestionQuality = () => {
    const [tests, setTests] = useState([]);
    const [activeMonths, setActiveMonths] = useState([new Date().getMonth(), new Date().getMonth() + 1]);

    const [activeTest, setActiveTest] = useState();
    const [activeDuration, setActiveDuration] = useState('last-two-months');
    const [customDurationStartDate, setCustomDurationStartDate] = useState();
    const [customDurationEndDate, setCustomDurationEndDate] = useState();
    const [activeSection, setActiveSection] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchTests();
    }, []);

    useEffect(() => {
        if (activeTest && activeDuration !== 'custom-duration') {
            refreshData();
        }
    }, [activeTest, activeDuration]);

    const fetchTests = async() => {
        const res = await axios.get(Constants.API_ROOT_URL + '/tests');
        res.data.sort((a, b) => a.currentScore - b.currentScore);
        setTests(res.data);
    }

    const refreshData = () => {
        console.log('refresh');
        // switch loading to true to force children components update
        setLoading(true);
        setTimeout(() => setLoading(false), 500);
    }

    const getDuration = () => {
        let startDate, endDate;
        if (activeDuration === 'last-two-months') {
            startDate = new Date();
            startDate.setMonth(startDate.getMonth() - 1);
            startDate.setDate(1);

            endDate = new Date();
            endDate.setMonth(endDate.getMonth() + 1);
            endDate.setDate(0);
        } else if (activeDuration === 'last-seven-days') {
            startDate = new Date();
            endDate = new Date();
            startDate.setDate(endDate.getDate() - 7);
        } else if (activeDuration === 'custom-duration') {
            startDate = new Date(customDurationStartDate);
            endDate = new Date(customDurationEndDate);
        }

        startDate = startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate();
        endDate = endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate();
        return {startDate, endDate};
    }

    return (
        <Container className='question-quality'>
            <Row>
                <Col md={2} className='filter'>
                    <Form>
                        <div className='filter-by-duration'>
                            <Form.Check type='radio' label={'Tháng ' + activeMonths[0] + ' + ' + activeMonths[1]} name='duration' checked={activeDuration === 'last-two-months'}
                                onChange={() => setActiveDuration('last-two-months')} id='filter-by-last-two-months' />
                            <Form.Check type='radio' label='Last 7 days' name='duration' checked={activeDuration === 'last-seven-days'}
                                onChange={() => setActiveDuration('last-seven-days')} id='filter-by-last-seven-days' />
                            <Form.Check type='radio' label='Custom Duration' name='duration' checked={activeDuration === 'custom-duration'}
                                onChange={() => setActiveDuration('custom-duration')} id='filter-by-custom-duration' />
                            {activeDuration === 'custom-duration' && (
                                <div className='custom-duration'>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Control placeholder="Start date" value={customDurationStartDate} onChange={e => setCustomDurationStartDate(e.target.value)} />
                                        <Form.Text className="text-muted">
                                            VD: 2021-12-01
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Control placeholder="End date" value={customDurationEndDate} onChange={e => setCustomDurationEndDate(e.target.value)} />
                                        <Form.Text className="text-muted">
                                            VD: 2021-12-31
                                        </Form.Text>
                                    </Form.Group>
                                    <Button onClick={refreshData}>Filter</Button>
                                </div>
                            )}
                        </div>
                        <div className='filter-by-sections'>
                            <Form.Check type='radio' label='Info' name='section' checked={activeSection === 'info'}
                                onChange={() => setActiveSection('info')} id='filter-by-info' />
                            <Form.Check type='radio' label='NPS' name='section' checked={activeSection === 'nps'}
                                onChange={() => setActiveSection('nps')} id='filter-by-nps' />
                            <Form.Check type='radio' label='Reported Mistakes' name='section' checked={activeSection === 'reported-mistakes'}
                                onChange={() => setActiveSection('reported-mistakes')} id='filter-by-reported-mistakes' />
                            <Form.Check type='radio' label='Ratings & Reviews' name='section' checked={activeSection === 'ratings-reviews'}
                                onChange={() => setActiveSection('ratings-reviews')} id='filter-by-ratings-reviews' />
                            <Form.Check type='radio' label='Messenger Feedbacks' name='section' checked={activeSection === 'messenger-feedbacks'}
                                onChange={() => setActiveSection('messenger-feedbacks')} id='filter-by-messenger-feedbacks' />
                            <Form.Check type='radio' label='Email Feedbacks' name='section' checked={activeSection === 'email-feedbacks'}
                                onChange={() => setActiveSection('email-feedbacks')} id='filter-by-email-feedbacks' />
                        </div>
                        <div className='filter-by-tests'>
                            {tests.map((test, index) => (
                                <Form.Check type='radio' id={'test-' + test.id} key={index}>
                                    <Form.Check.Input type='radio' name='test' onChange={() => setActiveTest(test)} />
                                    <Form.Check.Label>{test.name + ' (' + test.currentScore + ')'}</Form.Check.Label>
                                    <div className='test-score-detail'>
                                        <div>Report Mistake: {test.recentReportMistakeScore}/10</div>
                                        <div>NPS: {test.recentNPSScore}/10</div>
                                        <div>Review: {test.recentReviewScore}/10</div>
                                    </div>
                                </Form.Check>
                            ))}
                        </div>
                    </Form>
                </Col>
                <Col className='chart-panel' md={10}>
                    {activeTest && !loading && (
                        <div className='detail-panel'>
                            {activeSection === 'info' && <TestInfo test={activeTest} />}
                            {activeSection === 'nps' && <NPSAndReportMitstakes showNPS={true} appID={activeTest.appID} startDate={getDuration().startDate} endDate={getDuration().endDate} />}
                            {activeSection === 'reported-mistakes' && <NPSAndReportMitstakes showNPS={false} appID={activeTest.appID} startDate={getDuration().startDate} endDate={getDuration().endDate} />}
                            {activeSection === 'ratings-reviews' && <Reviews startDate={getDuration().startDate} endDate={getDuration().endDate} testID={activeTest.id} />}
                            {activeSection === 'messenger-feedbacks' && <MessengerFeedbacks testID={activeTest.id} />}
                            {activeSection === 'email-feedbacks' && <EmailFeedbacks testID={activeTest.id} />}
                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    )
}

function mapStateToProps(state, props) {
    return {
        filter: state.filterReducer,
    }
}

export default connect(mapStateToProps, { })(QuestionQuality);